<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div></div>
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="selected">
        <div class="selected-left">已选</div>
        <div class="selected-right">
          <div class="selected-item" v-for="item in role_obj.role_item" :key="item.id">
            <div>{{ item.name }}</div>
            <i @click="handleRemove(item)" class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <div class="table process">
        <div @click="handleItem(item)" v-for="item in role_type" :key="item.id" :class="[
          'item',
          role_obj.role_id.indexOf(item.id) != -1 ? 'itemActive' : '',
        ]">
          {{ item.name }}
        </div>
      </div>
      <div class="mes-foot">
        <button @click="handleRefund">取消</button>
        <button @click="handleSubmit" class="mes-foot-bg">保存</button>
      </div>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "AddAccount",
  props: ["dataVal"],
  data() {
    return {
      role_type: [],
      role_obj: {
        role_id: [],
        role_item: [],
      },
    };
  },
  mounted() { },
  methods: {
    //删除
    handleRemove(item) {
      let i = this.role_obj.role_id.indexOf(item.id);
      this.role_obj.role_id.splice(i, 1);
      this.role_obj.role_item.splice(i, 1);
    },
    //添加
    handleItem(item) {
      if (this.role_obj.role_id.indexOf(item.id) == -1) {
        this.role_obj.role_id.push(item.id);
        this.role_obj.role_item.push(item);
      }
    },
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
        submitFlag: false, //是否调用接口
      };
      this.$emit("AddProcess", obj);
    },
    async handleSubmit() {
      let obj = {
        flag: false,
        submitFlag: true, //是否调用接口
        role_obj: this.role_obj,
      };
      this.$emit("AddProcess", obj);
    },
    //得到角色
    async adminGetrole() {
      let res = await this.$api.adminGetrole();
      if (res.code == 1) {
        this.role_type = res.data;
      }
    },
  },
  created() {
    this.adminGetrole();
  },
  watch: {
    dataVal: {
      handler(newVal, oldVal) {
        console.log(newVal, "这样也可以得到数据~~~");
        if (newVal.type == 1) {
          this.role_obj.role_id = newVal.role_id;
          this.role_obj.role_item = newVal.role_item;
        } else {
          this.role_obj.role_id = [];
          this.role_obj.role_item = [];
        }
      },
      deep: true,
    },
  },
};
</script>
        
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/ .upload-demo {
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
    height: 13.407vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    .avater-img {
      width: auto;
      height: 100%;
    }
  }
}

.process {
  display: flex;
  flex-wrap: wrap;
  height: 35vh;
  overflow-y: auto;

  .item {
    min-width: 6.25vw;
    height: 3.627vh;
    background-color: #f8f8f8;
    border-radius: 0.52vw;
    color: #666666;
    font-size: 0.73vw;
    margin-right: 0.521vw;
    padding: 0 0.521vw;
    line-height: 3.627vh;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0.7886vh;
  }

  .itemActive {
    background-color: #6d79ff;
    color: #fff;
  }

  .item:hover {
    background-color: #6d79ff;
    color: #fff;
  }
}

.selected {
  display: flex;
  align-items: center;
  padding: 0 1.562vw;
  margin-bottom: 1.578vh;

  .selected-left {
    width: 7.29vw;
    text-align: center;
    font-size: 0.73vw;
    color: #333333;
    margin-right: 0.521vw;
    flex-shrink: 0;
  }

  .selected-right {
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding-bottom: 0.789vh;

    .selected-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 7.29vw;
      height: 3.627vh;
      background-color: #1d2ef7;
      border-radius: 0.52vw;
      padding: 0 0.521vw 0 1.354vw;
      box-sizing: border-box;
      margin-right: 0.521vw;
      flex-shrink: 0;

      >div {
        font-size: 0.73vw;
        color: #fff;
      }

      i {
        color: #fff;
        font-size: 0.73vw;
        cursor: pointer;
      }
    }
  }
}

.mes-foot {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.76vw;

  button {
    width: 6.302vw;
    height: 3.707vh;
    background-color: #ffffff;
    border-radius: 0.52vw;
    border: solid 0.05vw #999999;
    text-align: center;
    line-height: 3.707vh;
    color: #999999;
    font-size: 0.89vw;
    margin-right: 1.51vw;
    cursor: pointer;
  }

  .mes-foot-bg {
    background-color: #6d79ff;
    color: #fff;
  }
}

.flexDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.781vw;
  padding-left: 1.2vw;

  .flexDiv-right {
    display: flex;
    align-items: center;
  }

  .flexDiv-start {
    font-size: 0.73vw;
    color: #333;
  }

  .flexDiv-center {
    height: 1.104vh;
    width: 0.052vw;
    background-color: #aaaaaa;
    margin: 0 1.042vw;
  }

  .flexDiv-end {
    font-size: 0.73vw;
    color: #6d79ff;
    cursor: pointer;
  }
}

.order-mask .mask-main {
  width: 50vw;
  padding: 0;
  border-radius: 1.041vw;
  overflow: hidden;
}

.table table {
  width: 100%;
  margin-top: 1.577vh;
}

.order-mask .mask-main .mask-nav {
  padding: 2.366vh 1.562vw;
  background-color: #f8f8f8;
  margin-bottom: 1.498vh;
}

.order-mask .mask-main .table {
  padding: 0 1.562vw 2.366vh;
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.83vw;
}

.table table .table-td {
  font-weight: bold;
  color: #333;
}

/deep/ .el-input--suffix .el-input__inner {
  padding-left: 1.25vw;
}

.qrcode {
  width: 10.42vw;
  height: 15.773vh;
  margin: 3.549vh auto 0;
}
</style>