<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 新增 获奖经历 才艺特长  -->
    <div class="mask-main">
      <div class="mask-nav">
        <div></div>
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ dataVal.title1 }}
        </div>
      </div>
      <div class="voucher">
        <el-upload
          class="upload-demo"
          :action="urls + '/api/platform/index/uploadimg'"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-progress="handleProgress"
        >
          <div v-if="!obj.image" class="avatar_i">
            <img src="@/assets/images/icon/photo.png" />
            <div>上传获奖凭证</div>
          </div>
          <img v-else class="image-has" :src="urls + obj.image" alt="" />
        </el-upload>
        <div v-if="percentage != 0" class="flex-pro">
          <el-progress :percentage="percentage" status="success"></el-progress>
        </div>
      </div>

      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ dataVal.title2 }}
        </div>
      </div>
      <div :class="[obj.name?'caiyi caiyi-ac':'caiyi']" @click="handleAdd">{{ obj.name?obj.name:"请选择才艺特长" }}</div>
      <div class="mask-footer">
        <button v-if="dataVal.btnq" @click="handleRefund">
          {{ dataVal.btns }}
        </button>
        <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">
          {{ dataVal.btnq }}
        </button>
      </div>
    </div>
    <AddInterest2
      :dataVal="addInterest"
      @AddInterestCell="AddInterestCell"
    ></AddInterest2>
  </div>
</template>
  <script>
import AddInterest2 from "@/components/management/AddInterest2.vue";
export default {
  name: "AddTaltent2",
  props: ["dataVal"],
  data() {
    return {
      headers: {
        token: localStorage.getItem("token"),
      },
      urls: this.imgurl,
      options: [],
      obj: {
        image: "",
        name: "",
        id:""
      },
      percentage: 0,
      //添加兴趣/爱好
      addInterest: {
        flag: false,
        title: "添加兴趣/爱好",
        childlabel: [],
        checkId: [],
        checklist: [],
        obj:{},
        btns: "取消",
        btnq: "保存",
      },
    };
  },
  mounted() {
    console.log(this.data);
    this.childGetchildlabel();
  },
  methods: {
    //
    handleAdd(){
        this.addInterest.flag = true;
    },
    //得到童模标签
    async childGetchildlabel() {
      let res = await this.$api.childGetchildlabel();
      if (res.code == 1) {
        this.addInterest.childlabel = res.data;
      }
    },
    AddInterestCell(data){
        if (data.submitFlag) {
            this.obj.name = data.checklist[0].name;
            this.obj.id = data.checklist[0].id;
        }
        this.addInterest.flag = false;
    },
    //上传进度
    handleProgress(event, file, fileList) {
      this.percentage = parseInt(
        ((event.loaded / event.total) * 100).toFixed(0)
      );
    },
    //上传图片
    handleAvatarSuccess(res, file) {
      if (res.code == 1) {
        this.obj.image = res.data.imgsrc;
      }
      this.percentage = 0;
    },
    beforeAvatarUpload(file) {},
    //取消弹窗
    handleRefund() {
      let obj = {
        negativeFlag: false,
        submitFlag: false,
      };
      this.$emit("AdduardianCell2", obj);
    },
    handleSubmit() {
      let obj = {
        negativeFlag: false,
        submitFlag: true,
        obj: this.obj,
      };
      this.$emit("AdduardianCell2", obj);
    },
  },
  components:{
    AddInterest2
  },
  watch: {
    dataVal: {
      deep: true,
      handler(newVal, oldVal) {
        this.options = newVal.list;
        this.addInterest.obj = newVal.obj;
        if (newVal.type == "edit") {
          this.obj.image = newVal.obj.image;
          this.obj.name = newVal.obj.name;
          this.obj.id = newVal.obj.id;
        } else {
          this.obj.image = "";
          this.obj.name = "";
          this.obj.id = "";
          this.addInterest.checkId = [];
          this.addInterest.checklist = [];
          this.addInterest.obj = {};
        }
      },
    },
  },
};
</script>
  <style>
.voucher {
  display: flex;
  justify-content: center;
  position: relative;
}
.voucher .upload-demo {
  width: 18.75vw;
  height: 16.562vh;
}
.voucher .el-upload {
  display: flex;
  justify-content: center;
  background: #f9f9f9;
  border: 0.1vw solid #e6e6e6;
  align-items: center;
  width: 100%;
  height: 100%;
}
.voucher .image-has {
  width: 100%;
  height: 100%;
}
</style>
  <style lang="less" scoped>
@import url("@/assets/css/mask.less");
.caiyi {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.416vh;
  background: #ffffff;
  border: 0.0789vh solid #e6e6e6;
 font-size: 0.73vw;
  cursor: pointer;
  color: #AAAAAA;
}
.caiyi-ac {
    color: #6d79ff;
}
.flex-pro {
  position: absolute;
  width: 18.75vw;
  bottom: -1.578vh;
  left: 50%;
  transform: translateX(-50%);
}
.avatar_i {
  div {
   font-size: 0.73vw;
    margin-top: 1.577vh;
    color: #aaa;
  }
}
/deep/.table {
  padding-top: 1.577vh;

  textarea {
    height: 28.391vh;
    resize: none;
  }

  .el-radio-group {
    text-align: left;
    padding-left: 2.76vw;

    .el-radio__label {
      font-size: 0.83vw;
    }
  }
}
/deep/.upload-demo {
  display: flex !important;
  justify-content: center;
}
/deep/.el-upload-dragger {
 width: 18.75vw !important;
  height: 16.562vh !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/deep/.el-input__inner {
  width: 100% !important;
  height: 4.416vh !important;
  background-color: #f9f9f9 !important;
  border: solid 0vw #e6e6e6 !important;
}
/deep/.el-select {
  width: 100%;
}

.save {
  margin: 0 !important;
}

.table > div:nth-child(2) {
  font-family: MicrosoftYaHei;
 font-size: 0.73vw;
  color: #ff0000;
  margin-top: 0.71vh;
}

.table > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2.839vh;
}

.mask-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.76vh;

  button {
    width: 7.81vw;
    height: 3.627vh;
    border-radius: 0.31vw;
   font-size: 0.73vw;
    cursor: pointer;
  }

  button {
    background-color: #ffffff;
    border: solid 0.05vw #999999;
    color: #999999;
    margin-right: 3.13vw;
  }

  button:last-child {
    margin-right: 0;
  }

  .btn-bg {
    background: #6d79ff;
    border: 0.0789vh solid #6d79ff;
    color: #fff;
  }
}
.order-mask .mask-main {
  width: 36.458vw;
}
.order-mask .mask-main .mask-nav {
  border: 0;
}

.voucher {
  margin-bottom: 3.155vh;
}
</style>