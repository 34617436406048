import Vue from 'vue'
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入vue-router
import router from './router/index'  //刚刚建立的文件夹
import VueRouter from 'vue-router'
import App from './App.vue'

//富文本

//引入组件
import Component from '@/utils/index.js'

//时间过滤
import FilDate from '@/utils/filter/data'
Vue.prototype.$FilDate = FilDate;

//引入cookies
import Cookies from 'js-cookie'
Vue.prototype.$cookie = Cookies;

Vue.prototype.imgurl = "https://xcx.modelbaby.com";
//全局过滤器 秒
Vue.filter('DateTime', function (e,type=1) {
  let d = new Date(e * 1000);
  let year = d.getFullYear();
  let month = d.getMonth();
  month = month + 1 > 12 ? 1 : month + 1;
  month = month > 9 ? month : "0" + month.toString();
  let day = d.getDate();
  day = day > 9 ? day : "0" + day.toString();
  let hour = d.getHours();
  hour = hour > 9 ? hour : "0" + hour.toString();
  let minute = d.getMinutes();
  minute = minute > 9 ? minute : "0" + minute.toString();
  let second = d.getSeconds();
  second = second > 9 ? second : "0" + second.toString();

  let str = ""
  if (type==1) {
    str = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }else if(type=="2"){
    str = `${year}年${month}月${day}日`;
  }else if(type=="3"){
    str = `${year}-${month}-${day}`;
  }
  return str
}
)


//引入API
import API from '@/request/api';
Vue.prototype.$api = API;


//使用element-ui
Vue.use(ElementUI);
//使用vue-router
Vue.use(VueRouter)
//全局注册组件
Vue.use(Component)



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
