import { render, staticRenderFns } from "./AddGuardian.vue?vue&type=template&id=a91f13c0&scoped=true&"
import script from "./AddGuardian.vue?vue&type=script&lang=js&"
export * from "./AddGuardian.vue?vue&type=script&lang=js&"
import style0 from "./AddGuardian.vue?vue&type=style&index=0&id=a91f13c0&prod&lang=less&"
import style1 from "./AddGuardian.vue?vue&type=style&index=1&id=a91f13c0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a91f13c0",
  null
  
)

export default component.exports