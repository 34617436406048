<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 添加订单 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <!-- <div></div> -->
          {{ users.edit == "0" ? "添加" : "编辑" }}{{ users.title }}
        </div>
        <i class="el-icon-close" @click="handleRefund"></i>
      </div>
      <div class="mask-content">
        <el-input v-model="users.name" :placeholder="users.placeholder"></el-input>
      </div>
      <div class="mask-footer">
        <button v-if="dataVal.btnq" @click="handleRefund">
          {{ dataVal.btnq }}
        </button>
        <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">
          {{ dataVal.btns }}
        </button>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "ModelLabel",
  props: ["dataVal"],
  data() {
    return {
      users: {
        title: "",
        name: "",
        placeholder: "",
        edit: "0"
      },
    };
  },
  mounted() { },
  methods: {
    //取消弹窗
    handleRefund() {
      let data = {
        flag: false,
        submitFlag: false,
      };
      this.$emit("ModelLabel", data);
    },
    handleSubmit() {
      let data = {
        flag: false, //弹窗是否显示
        submitFlag: true, //是否调用接口
        name: this.users.name
      };
      this.$emit("ModelLabel", data);
    },
  },
  watch: {
    dataVal: {
      handler(newVal, oldVal) {
        // console.log(newVal);
        //修改
        if (newVal.edit == "1") {
          this.users.name = newVal.content;
        } else {
          this.users.name = "";
        }
        this.users.title = newVal.title;
        this.users.placeholder = newVal.placeholder;
        this.users.edit = newVal.edit;
      },
      deep: true,
    },
  },
};
</script>
  
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
  .mask-main {
    width: 24.48vw;
    border-radius: 1.104vh 0.52vw 0.7886vh 0.52vw;
  }

  .mask-content {
    display: flex;
    align-items: flex-start;
    font-size: 0.83vw;
    color: #333333;
    height: 6.309vh;
    margin: 2.05vh 0 1.577vh;

    i {
      font-size: 1.25vw;
      color: #066ff8;
      margin-right: 0.521vw;
    }
  }

  .mask-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 7.81vw;
      height: 3.627vh;
      border-radius: 0.31vw;
      font-size: 0.73vw;
      cursor: pointer;
    }

    button {
      background: #fff;
      border: 0.0789vh solid #6d79ff;
      color: #6d79ff;
      margin-right: 3.13vw;
    }

    button:last-child {
      margin-right: 0;
    }

    .btn-bg {
      background: #6d79ff;
      border: 0.0789vh solid #6d79ff;
      color: #fff;
    }
  }
}
</style>