import { render, staticRenderFns } from "./ViewAttachments.vue?vue&type=template&id=024dc443&scoped=true&"
import script from "./ViewAttachments.vue?vue&type=script&lang=js&"
export * from "./ViewAttachments.vue?vue&type=script&lang=js&"
import style0 from "./ViewAttachments.vue?vue&type=style&index=0&id=024dc443&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024dc443",
  null
  
)

export default component.exports