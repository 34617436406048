<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 添加演员需求 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i class="el-icon-close" @click="handleRefund"></i>
      </div>
      <!-- <div class="mask-content"> -->
      <div class="filei">
        <div class="cen">
          <div class="cen-table">
            <table>
              <tr>
                <td class="cen-tabtd xin2">性别</td>
                <td>
                  <el-select :popper-append-to-body="false" v-model="fromObj.sex" placeholder="请选择性别" class="selec">
                    <el-option v-for="item in sexArr" :key="item.values" :label="item.values" :value="item.values">
                    </el-option>
                  </el-select>
                </td>
                <td class="cen-tabtd xin2">招募人数</td>
                <td>
                  <input v-model="fromObj.counts" type="number" placeholder="请输入招募人数" />
                </td>
                <td class="cen-tabtd">年龄(岁)</td>
                <td>
                  <el-input class="input_age" type="number" v-model="fromObj.min_age" placeholder="请输入最小年龄"></el-input>-
                  <el-input class="input_age" type="number" v-model="fromObj.max_age" placeholder="请输入最大年龄"></el-input>
                </td>
              </tr>
              <tr>
                <td class="cen-tabtd">身高(cm)</td>
                <td>
                  <el-input class="input_age" type="number" v-model="fromObj.min_high" placeholder="请输入最低身高"></el-input>-
                  <el-input class="input_age" type="number" v-model="fromObj.max_high" placeholder="请输入最大身高"></el-input>
                </td>
                <td class="cen-tabtd">体重(kg)</td>
                <td>
                  <el-input class="input_age" type="number" v-model="fromObj.min_weight"
                    placeholder="请输入最低体重"></el-input>-
                  <el-input class="input_age" type="number" v-model="fromObj.max_weight" placeholder="请输入最大体重"></el-input>
                </td>
                <td class="cen-tabtd">鞋码(码)</td>
                <td>
                  <el-input class="input_age" type="number" v-model="fromObj.min_shoesize"
                    placeholder="请输入最小鞋码"></el-input>-
                  <el-input class="input_age" type="number" v-model="fromObj.max_shoesize"
                    placeholder="请输入最大鞋码"></el-input>
                </td>
              </tr>
              <tr>
                <td class="cen-tabtd xin2">首推标签</td>
                <td colspan="5">
                  <el-cascader :props="{ multiple: true }" v-model="label_sel" :options="labelArr"
                    placeholder="请选择首推标签"></el-cascader>
                </td>
              </tr>
              <tr>
                <td class="cen-tabtd xin2">报名要求</td>
                <td colspan="5">
                  <el-cascader :props="{ multiple: true }" v-model="signask_sel" :options="signaskArr"
                    placeholder="请选择报名要求"></el-cascader>
                  <!-- <el-select
                    v-model="fromObj.signask"
                    placeholder="请选择报名要求"
                    class="selec"
                  >
                    <el-option
                      v-for="item in signaskArr"
                      :key="item.values"
                      :label="item.values"
                      :value="item.values"
                    >
                    </el-option>
                  </el-select> -->
                </td>
              </tr>
              <tr>
                <td class="cen-tabtd xin2">通告要求</td>
                <td colspan="5">
                  <el-input type="textarea" placeholder="请输入内容" v-model="fromObj.informask" maxlength="100"
                    show-word-limit></el-input>
                </td>
              </tr>
              <tr>
                <td class="cen-tabtd">备注</td>
                <td colspan="5">
                  <el-input type="textarea" placeholder="请输入内容" v-model="fromObj.remark" maxlength="100"
                    show-word-limit></el-input>
                </td>
              </tr>
            </table>
          </div>

          <!-- </div> -->
          <div class="mask-footer">
            <button v-if="dataVal.btnq" @click="handleRefund">
              {{ dataVal.btnq }}
            </button>
            <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">
              {{ dataVal.btns }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "Addactorrequirements",
  props: ["dataVal"],
  data() {
    return {
      value: [],
      options: [],
      optionsvalue: "",
      pushObj: {
        flag: false, //是否显示
        title: "批量推送预约单", //标题
        text: "确定要批量推送该预约单吗？", //内容
        btns: "确定", //确定按钮
        btnq: "取消", //取消按钮
      },
      addactorObj: {
        flag: true, //是否显示
        title: "添加演员需求", //标题
        btns: "确定", //确定按钮
        btnq: "取消", //取消按钮
      },
      //性别选择
      sexArr: [
        {
          values: "不限",
        },
        {
          values: "男",
        },
        {
          values: "女",
        },
      ],
      fromObj: {
        sex: "", //性别
        counts: "", //招募人数
        min_age: "", //最小年龄
        max_age: "", //最大年龄
        min_high: "", //最低身高
        max_high: "", //最高身高
        min_weight: "", //最低体重
        max_weight: "", //最大体重
        min_shoesize: "", //最小鞋码
        max_shoesize: "", //最大鞋码
        label_ids: "", //首推标签id 逗号隔开
        signask: "", //报名要求
        informask: "", //通告要求
        remark: "", //备注
        id: "", //演员需求id 编辑必填
        label_sel: [],
        label_val: "",
      },
      //首推标签
      labelArr: [],
      label_sel: [],
      //报名要求
      signaskArr: [],
      signask_sel: []
    };
  },
  mounted() {
    this.dictionaryGetlabel();
    this.dictionaryGetsigncondition();
  },
  methods: {
    async dictionaryGetsigncondition() {
      let res = await this.$api.dictionaryGetsigncondition();
      if (res.code == 1) {

        res.data.forEach((item) => {
          item.label = item.values;
          item.value = item.values;
        });
        this.signaskArr = res.data;
      }
    },
    async dictionaryGetlabel() {
      let res = await this.$api.dictionaryGetlabel();
      if (res.code == 1) {
        res.data.forEach((item) => {
          item.label = item.values;
          item.value = JSON.stringify(item);
        });
        let obj = {
          label:"全选",
          value:0,
          children:res.data
        }
        
        this.labelArr = obj;
        // console.log(this.labelArr);
      }
    },
    //取消弹窗
    handleRefund() {
      let data = {
        flag: false,
        submitFlag: false,
      };
      this.$emit("addactorObjCall", data);
    },
    handleSubmit() {
      // console.log(this.label_sel);
      if (this.fromObj.sex == "") {
        this.$message({
          message: "请选择性别",
          type: "error",
        });
        return false;
      }
      if (this.fromObj.counts == "") {
        this.$message({
          message: "请输入招募人数",
          type: "error",
        });
        return false;
      }
      // if (this.fromObj.min_age == "") {
      //   this.$message({
      //     message: "请输入最小年龄",
      //     type: "error",
      //   });
      //   return false;
      // }
      // if (this.fromObj.max_age == "") {
      //   this.$message({
      //     message: "请输入最大年龄",
      //     type: "error",
      //   });
      //   return false;
      // }
      // if (this.fromObj.min_high == "") {
      //   this.$message({
      //     message: "请输入最低身高",
      //     type: "error",
      //   });
      //   return false;
      // }
      // if (this.fromObj.max_high == "") {
      //   this.$message({
      //     message: "请输入最高身高",
      //     type: "error",
      //   });
      //   return false;
      // }
      // if (this.fromObj.min_weight == "") {
      //   this.$message({
      //     message: "请输入最低体重",
      //     type: "error",
      //   });
      //   return false;
      // }
      // if (this.fromObj.max_weight == "") {
      //   this.$message({
      //     message: "请输入最大体重",
      //     type: "error",
      //   });
      //   return false;
      // }
      // if (this.fromObj.min_shoesize == "") {
      //   this.$message({
      //     message: "请输入最小鞋码",
      //     type: "error",
      //   });
      //   return false;
      // }
      // if (this.fromObj.max_shoesize == "") {
      //   this.$message({
      //     message: "请输入最大鞋码",
      //     type: "error",
      //   });
      //   return false;
      // }
      if (this.label_sel.length == 0) {
        this.$message({
          message: "请选择首推标签",
          type: "error",
        });
        return false;
      }
      if (this.signask_sel.length == 0) {
        this.$message({
          message: "请输入报名要求",
          type: "error",
        });
        return false;
      }
      if (this.fromObj.informask == "") {
        this.$message({
          message: "请输入通告要求",
          type: "error",
        });
        return false;
      }
      // if (this.fromObj.remark == "") {
      //   this.$message({
      //     message: "请输入备注",
      //     type: "error",
      //   });
      //   return false;
      // }
      // let arr3 = [];
      // let arr4 = [];
      // this.signask_sel.forEach((item) => {
      //   arr3.push(JSON.parse(item[0]).id);
      //   arr4.push(JSON.parse(item[0]).values);
      // });
      console.log(this.label_sel);
      let arr = [];
      let arr2 = [];
      this.label_sel.forEach((item) => {
        arr.push(JSON.parse(item[1]).id);
        arr2.push(JSON.parse(item[1]).values);
      });
      this.fromObj.signask = this.signask_sel.flat().join("/");
      this.fromObj.label_val = arr2.join("/");
      this.fromObj.label_sel = this.label_sel;
      this.fromObj.label_ids = arr.toString();
      let data = {
        flag: false, //弹窗是否显示
        submitFlag: true, //是否调用接口
        obj: this.fromObj,
      };
      this.$emit("addactorObjCall", data);
    },
  },
  watch: {
    dataVal: {
      handler(newVal, oldVal) {
        console.log(newVal);
        if (newVal.type == "edit") {
          if (newVal.obj.id) {
            let arr = [];
            newVal.obj.label.forEach((item) => {
              let obj = {
                id: item.id,
                values: item.values,
                label: item.values,
              };
              arr.push([0,JSON.stringify(obj)]);
            });
            this.fromObj = newVal.obj;
            // console.log(arr);
            this.label_sel = arr;
            this.signask_sel = [];
            newVal.obj.signask.split('/').forEach(item => {
              this.signask_sel.push([item])
            })
            // console.log(this.signask_sel);
            //   console.log(arr);
            this.fromObj.id = newVal.id;
          } else {
            this.signask_sel = [];
            newVal.obj.signask.split('/').forEach(item => {
              this.signask_sel.push([item])
            })
            this.fromObj = newVal.obj;
            this.label_sel = newVal.obj.label_sel;
          }
        } else {
          // this.labelArr = [];
          this.label_sel = [];
          this.signask_sel = [];
          this.fromObj = {
            sex: "", //性别
            counts: "", //招募人数
            min_age: "", //最小年龄
            max_age: "", //最大年龄
            min_high: "", //最低身高
            max_high: "", //最高身高
            min_weight: "", //最低体重
            max_weight: "", //最大体重
            min_shoesize: "", //最小鞋码
            max_shoesize: "", //最大鞋码
            label_ids: "", //首推标签id 逗号隔开
            signask: "", //报名要求
            informask: "", //通告要求
            remark: "", //备注
            id: "", //演员需求id 编辑必填
            label_sel: [],
            label_val: "",
          };
        }
      },
      deep: true,
    },
  },
};
</script> 
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #6d79ff;
  border-color: #6d79ff;
}

.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #6d79ff;
}
</style>
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.less");
@import url("@/assets/css/reservation/telement.less");
@import url("@/assets/css/reservation/telement2.less");

.order-mask {
  /deep/ .el-textarea {
    margin-top: 0;
  }

  /deep/.el-cascader {
    width: 100%;
  }

  .mask-main {
    width: 61vw;
    border-radius: 1.104vh 0.52vw 0.7886vh 0.52vw;

    .mask-nav {
      border: 0;
    }
  }

  // .mask-content {
  //     display: flex;
  //     align-items: flex-start;
  //     font-size: 0.83vw;
  //     color: #333333;
  //     // height: 6.309vh;
  //     margin: 2.05vh 0 1.577vh;

  //     i {
  //         font-size: 1.25vw;
  //         color: #066ff8;
  //         margin-right: 0.521vw;
  //     }
  // }

  .mask-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.577vh;

    button {
      width: 7.81vw;
      height: 3.627vh;
      border-radius: 0.31vw;
      font-size: 0.73vw;
      background: #fff;
      border: solid 0.05vw #999999;
      color: #999999;
      margin-right: 3.13vw;
      cursor: pointer;
    }

    button:last-child {
      margin-right: 0;
    }

    .btn-bg {
      background: #6d79ff;
      border: 0.0789vh solid #6d79ff;
      color: #fff;
    }
  }
}

.filei .cen {
  height: auto;
  padding-bottom: 0;
  padding-left: 0;
}

.input_age {
  width: 45%;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: white;
  border: 0;
}
</style>
<style>
.filei .cen-table textarea {
  border: none;
}
</style>