<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: {
    Header,
  }
}
</script>

<style lang="less">
body {
  margin: 0;
}

.el-table .cell {
  padding-left: 0.16vw !important;
  padding-right: 0.16vw !important;
}

::-webkit-scrollbar {
  width: 0.21vw;
  height: 0.315vh;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.26vw;
  -webkit-box-shadow: inset 0 0 0.26vw rgba(0, 0, 0, 0.2);
  background-color: #99a9bf;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.26vw rgba(0, 0, 0, 0.2);
  border-radius: 0.26vw;
  background-color: #d3dce6;
}

div {
  font-size: 0.83vw;
  color: #333;
}

p {
  font-size: 0.83vw;
  color: #333;
}

span {
  font-size: 0.83vw;
  /* color: #333; */
}

td {
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  outline: none;
}

textarea {
  font-family: auto !important;
  font-size: 0.833vw !important;
  color: #333 !important;
}

input::input-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
}

input::-webkit-input-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
}

input::-moz-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
}

input::-moz-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
}

input::-ms-input-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
}

textarea::input-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
  font-family: none;
}

textarea::-webkit-input-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
  font-family: none;
}

textarea::-moz-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
  font-family: none;
}

textarea::-moz-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
  font-family: none;
}

textarea::-ms-input-placeholder {
  font-size: 0.83vw;
  color: #999 !important;
  font-family: none;
}

.conent {
  padding-bottom: 2.188vw;
  // padding-right: 1.042vw;
}

/* input 类型为 number 时去掉数字加减上下箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* 此处写不写都可以 */
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
  /* 此处写不写都可以 */
}

/deep/.el-table .el-table__cell {
  padding: 1.262vh 0;
}

.el-table .el-table__cell {
  padding: 1.262vh 0;
}

.el-date-editor .el-range-input {
  font-size: 0.83vw;
}

.el-pagination button,
.el-pagination span:not([class*=suffix]) {
  display: inline-block;
  font-size: 0.83vw;
  min-width: 1.85vw;
  height: 2.84vh;
  line-height: 2.76vh;
  vertical-align: top;
  box-sizing: border-box;
}

.el-pagination__editor.el-input .el-input__inner {
  height: 2.76vh;
  font-size: 0.83vw;
}

.el-input--mini .el-input__inner {
  height: 2.76vh;
  line-height: 2.76vh;
}
</style>