<template>
  <div class="order-mask" v-if="data.flag">
    <!-- 上传附件 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <!-- <div></div> -->
          {{ data.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <el-upload
          ref="upload"
          class="upload-demo"
          :show-file-list="true"
          :before-upload="beforeAvatarUpload"
          drag
          :on-change="hanlechange"
          :headers="headers"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :file-list="fileList"
          :action="imgurl + '/api/platform/index/uploadfile'"
        >
          <div class="el-upload__text">
            <img src="@/assets/images/icon/import.png" alt="" />
            将文件拖到此处或点击上传
          </div>
          <div class="el-upload__tip" slot="tip">
            <span class="file">{{ result }}</span>
          </div>
        </el-upload>
        <div class="mask-footer">
          <button v-if="data.btnq" @click="handleRefund">
            {{ data.btns }}
          </button>
          <button v-if="data.btns" class="btn-bg" @click="handleSubmit">
            {{ data.btnq }}
          </button>
        </div>
        <!-- <div class="save" @click="handleSubmit">{{ data.btn }}</div> -->
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "BatchImport",
  props: ["data"],
  data() {
    return {
      textarea: "",
      result: "",
      headers: {
        token: localStorage.getItem("token"),
      },
      file: "",
      fileLists: [],
      fileList:[]
    };
  },
  mounted() {
    console.log(localStorage.getItem("token"));
  },
  methods: {
    hanlechange(file, fileList) {
      // this.file = file.raw;
    //   console.log(this.fileList);
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 获取最后一次选择的文件
      }
    },
    handleSuccess(response, file, fileList) {
      console.log(response);
      if (response.code == 1) {
        this.file = response.data.file;
        this.fileLists.push(response.data.file);
      } else {
        this.$refs["upload"].clearFiles();
        this.$message({
          message: response.msg,
          type: "error",
        });
      }
    },
    handleRemove(file, fileList) {
      this.fileLists = this.fileLists.filter((item) => {
        return item != file.response.data.file;
      });
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.name.slice(-4) === '.pdf'
      // if (!isJPG) {
      //     this.$message({
      //         message: '上传文件只能是 PDF 格式',
      //         type: 'error'
      //     })
      // }
      // return isJPG
    },
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
        flags: false,
      };
      this.$emit("BatchImportCell", obj);
    },
    handleSubmit() {
      this.file = JSON.parse(JSON.stringify(this.file)) + "";
      let obj = {
        flag: false,
        flags: true,
        data: {
          file: this.file,
        },
      };
      this.$emit("BatchImportCell", obj);
      this.file = [];
      this.fileLists = [];
      this.fileList = [];
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newvalue, oldvalue) {
        // if (newvalue.title == "批量导入预约单") {
        //     let data
        //     this.unlodeurl = "https://jiekou.paiyide.cc/api/store/order/import"
        // } else if (newvalue.title == "批量导入库位单") {
        //     this.unlodeurl = "https://jiekou.paiyide.cc/api/store/locator/import"
        // } else if (newvalue.title == "批量导入拍摄主题") {
        //     this.unlodeurl = "https://jiekou.paiyide.cc/api/store/theme/import"
        // }
      },
    },
  },
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
.order-mask .mask-main {
  width: 29.17vw;
}
/deep/.table {
  padding-top: 1.577vh;

  textarea {
    height: 28.391vh;
    resize: none;
  }
}

.download {
  font-size: 0.83vw;
  margin-bottom: 1.89vh;

  span {
    color: #ff0000;
  }
}

/deep/.upload-demo {
  .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
      height: 5.521vh;
      font-size: 0.83vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-upload__text {
        font-size: 0.83vw;
        color: #666666;

        img {
          width: 0.94vw;
          height: 1.262vh;
          margin-right: 0.57vw;
        }
      }
    }
  }

  .el-upload__tip {
    color: #ff0000;
  }
}
.mask-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.76vh;

  button {
    width: 7.81vw;
    height: 3.627vh;
    border-radius: 0.31vw;
   font-size: 0.73vw;
    cursor: pointer;
    background: #fff;
    border: solid 0.05vw #999999;
    color: #999999;
    margin-right: 3.13vw;
  }

  button:last-child {
    margin-right: 0;
  }

  .btn-bg {
    background: #6d79ff;
    border: 0.0789vh solid #6d79ff;
    color: #fff;
  }
}
</style>