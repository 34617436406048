<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
}
</script>

<style lang="less">
body {
  margin: 0;
  background-color: #f2f5ff;
}

.over-auto {
  height: 87.539vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.el-table .cell {
  padding-left: 0.16vw !important;
  padding-right: 0.16vw !important;
}

.el-pagination__editor.el-input .el-input__inner {
  height: 2.76vh !important;
  font-size: 2.208vh !important;
}

.el-input--mini .el-input__inner {
  height: 2.76vh !important;
  line-height: 2.76vh !important;
}

::-webkit-scrollbar {
  width: 0.42vw;
  height: 0.631vh;
  cursor: pointer;
}

.conent {
  height: calc(100vh - 4.479vw);
  padding-right: 1.042vw;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.26vw;
  -webkit-box-shadow: inset 0 0 0.394vh #6D79FF;
  background-color: #99a9bf;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.394vh #d3dce6;
  border-radius: 0.26vw;
  background-color: #d3dce6;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #6D79FF !important;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  // color: #6D79FF !important;
}

.el-select-dropdown__item.selected {
  color: #6D79FF !important;
}

div {
  font-size: 0.73vw;
  color: #333;
}

p {
  font-size: 0.73vw;
  color: #333;
}

span {
  font-size: 0.73vw;
  /* color: #333; */
}

td {
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  outline: none;
}

textarea {
  font-family: auto !important;
  font-size: 2.208vh !important;
  color: #333 !important;
}

input::input-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
}

input::-webkit-input-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
}

input::-moz-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
}

input::-moz-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
}

input::-ms-input-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
}

textarea::input-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
  font-family: none;
}

textarea::-webkit-input-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
  font-family: none;
}

textarea::-moz-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
  font-family: none;
}

textarea::-moz-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
  font-family: none;
}

textarea::-ms-input-placeholder {
  font-size: 0.73vw;
  color: #999 !important;
  font-family: none;
}

.conent {
  padding-bottom: 2.188vw;
  // padding-right: 1.042vw;
}

.conent {
  overflow-y: hidden
}

/* input 类型为 number 时去掉数字加减上下箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* 此处写不写都可以 */
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
  /* 此处写不写都可以 */
}

/deep/.el-table .el-table__cell {
  padding: 0.42vw 0;
}

.el-table .el-table__cell {
  padding: 0.42vw 0;
}

.el-date-editor .el-range-input {
  font-size: 0.73vw;
}

.el-pagination button,
.el-pagination span:not([class*=suffix]) {
  display: inline-block;
  font-size: 0.73vw;
  min-width: 1.85vw;
  height: 2.84vh;
  line-height: 2.76vh;
  vertical-align: top;
  box-sizing: border-box;
}

.el-pagination__editor.el-input .el-input__inner {
  height: 2.76vh;
  font-size: 0.73vw;
}

.table table {
  width: 100% !important;
}

.el-pager li {
  height: 2.84vh;
  line-height: 2.84vh;
}

.el-select .el-input .el-select__caret {
  font-size: 0.73vw;
}

.el-cascader .el-input .el-icon-arrow-down {
  font-size: 0.73vw;
}

.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 0.73vw;
}

// 级联选择器下拉框样式 -- 开始
.el-cascader-menu {
  min-width: 10vw !important;
}

.el-cascader-menu__wrap {
  height: 18.889vh;
}

.el-cascader-menu__list>.el-cascader-node {
  padding: 0 1.562vw 0 1.044vw;
  height: 3.148vh !important;
  line-height: 3.148vh !important;
}

.el-cascader-node__label {
  padding: 0 0.521vw;
}

.el-icon-circle-close:before {
  font-size: 0.73vw;
}

// 级联选择器下拉框样式 -- 结束

// 时间选择器 -- 开始
.el-date-range-picker {
  width: 33.65vw;
}

.el-date-range-picker__header {
  height: 2.593vh;
  margin-top: 0.741vh !important;
  padding: 0.052vw 0.556vh !important;
}

.el-date-range-picker__time-header {
  line-height: 2.13vh;
  font-size: 0.63vw;
  padding: 0.741vh 0.26vw 0.463vh;
}

.el-date-range-picker__time-header>.el-icon-arrow-right {
  font-size: 1.042vw;
}

.el-input--small {
  font-size: 0.68vw;

  .el-input__inner {
    height: 2.963vh;
    line-height: 2.963vh;
    padding: 0 0.781vw;
    font-size: 0.63vw;
  }
}

.el-date-range-picker .el-picker-panel__body {
  width: 33.65vw;
  min-width: 33.65vw;
}

.el-date-range-picker__content {
  padding: 0.833vw;
}

.el-picker-panel__icon-btn {
  font-size: 0.63vw;
  margin-top: 0.741vh;
}

.el-date-range-picker__content .el-date-range-picker__header div {
  margin: 0 2.604vw;
  font-size: 0.833vw;
  line-height: 2.778vh;
}

.el-date-table th {
  padding: 0.26vw;
  font-size: 0.63vw;
  line-height: 2.778vh;
}

.el-date-table td {
  width: 1.67vw;
  height: 2.778vh;
  padding: 0.37vh 0;
}

.el-date-table td div {
  height: 2.778vh;
  padding: 0.278vh 0;
}

.el-date-table td span {
  width: 1.25vw;
  height: 1.25vw;
  line-height: 1.25vw;
}

.el-picker-panel__footer {
  padding: 0.21vw;
  line-height: 2.778vh;
}

.el-button--mini {
  padding: 0.648vh 0.781vw;
  font-size: 0.63vw;

  span {
    font-size: 0.63vw;
  }
}

.el-time-panel__content::after,
.el-time-panel__content::before {
  height: 2.963vh;
  margin-top: -1.389vh;
}

.el-time-spinner__wrapper {
  max-height: 17.593vh;
}

.el-time-spinner__list::after,
.el-time-spinner__list::before {
  height: 7.407vh;
}

.el-time-spinner__item {
  height: 2.963vh;
  line-height: 2.963vh;
  font-size: 0.63vw;
}

.el-time-panel__footer {
  padding: 0.21vw;
  height: 3.333vh;
  line-height: 2.315vh;

  .el-time-panel__btn {
    line-height: 2.593vh;
    padding: 0 0.26vw;
    margin: 0 0.26vw;
    font-size: 0.63vw;
  }
}

.el-date-editor .el-range__icon {
  font-size: 0.73vw;
}

// 时间选择器 -- 结束

// 消息提示 -- 开始
.el-message {
  top: 1.852vh !important;
  min-width: 19.792vw;
  border-radius: 0.21vw;
  border-width: 0.052vw;
  padding: 1.389vh 0.781vw 1.389vh 1.042vw;

  .el-message__content {
    font-size: 0.73vw;
  }
}

// 消息提示 -- 结束

// 开关 -- 开始
.el-switch__core {
  width: 2.083vw !important;
  height: 1.042vw !important;
  border: 0.052vw !important;
  line-height: 1.852vh !important;
}

.el-switch__core:after {
  width: 0.833vw !important;
  height: 0.833vw !important;
  margin-left: -0.885vw !important;
  top: 0.13vh !important;
  left: 0.885vw;
  line-height: 1.852vh;
}

// 开关 -- 结束

// 分页器
.paging {
  min-width: 2.383vw !important;
  // top: 74vh !important;
  // left: 46.5vw !important;
  margin: 0.463vh 0 !important;
  margin-bottom: 1.111vh !important;

  .el-scrollbar {
    .el-select-dropdown__wrap {
      max-height: 25.37vh !important;
    }
  }
}

.el-select-dropdown__item {
  font-size: 0.73vw !important;
  padding: 0 1.042vw !important;
  height: 3.148vh !important;
  line-height: 3.148vh !important;

  span {
    font-size: 0.73vw !important;
  }
}

// 分页器 -- 结束

/deep/.el-input {
  display: flex;
  align-items: center;
}

// 文本域长度限制
.el-textarea .el-input__count {
  font-size: 0.625vw;
  bottom: 0.463vh;
  right: 0.521vw;
}
</style>
