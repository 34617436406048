//全局注册组件
//拍摄完成/拍摄未完成弹窗
import Tag from '@/components/Tag.vue';
//滑块验证
import SliderCheck from '@/components/SliderCheck.vue';
//批量推送预约单
import MaskPack from '@/components/management/MaskPack.vue';
//上传附件
import BatchImport from '@/components/management/BatchImport.vue';
//批量导入
import BatchImports from '@/components/management/childModelLabel/BatchImports.vue';
//填写邀请码
import InvitationCode from '@/components/management/InvitationCode.vue';
//添加兴趣爱好
import AddInterest from '@/components/management/AddInterest.vue';
//添加监护人信息
import AddGuardian from '@/components/management/AddGuardian.vue';
//添加监护人信息
import ModelLabel from '@/components/management/ModelLabel.vue';
//新增 获奖经历 才艺特长 
import AddTaltent from '@/components/management/reservation/AddTaltent.vue';
//新增 获奖经历 才艺特长 
import AddTaltent2 from '@/components/management/reservation/AddTaltent2.vue';
//批量审核拒绝 审核成功
import Toexamine from '@/components/management/childmodelreview/Toexamine.vue';
//原因
import FailReason from '@/components/management/FailReason.vue';
//添加演员需求
import Addactorrequirements from '@/components/management/notificationmanagement/Addactorrequirements.vue';
//推送萌宝
import ViewMengbao from '@/components/management/notificationmanagement/ViewMengbao.vue';
//添加广告
import AddAdvertisement from '@/components/management/advertisement/AddAdvertisement.vue';
//添加子账号
import AddAccount from '@/components/system/AddAccount.vue';
//添加审批流程
import AddProcess from '@/components/system/AddProcess.vue';
//添加消息设置
import AddProcess2 from '@/components/system/AddProcess2.vue';
//新增请求参数
import AddParameters from '@/components/system/AddParameters.vue';
//添加角色
import AddAuth from '@/components/system/AddAuth.vue';
//重新绑定 二维码
import BindingAccount from '@/components/system/BindingAccount.vue';
//查看处理结果
import ProcessingProgress from '@/components/management/evaluation/ProcessingProgress.vue';
//查看附件
import ViewAttachments from '@/components/management/evaluation/ViewAttachments.vue';
//重新关联经纪人
import Brokerinfo from '@/components/management/Brokerinfo.vue';
//重置密码
// import {ResetPassword} from "@/components/ResetPassword.vue"

//数组存放接收的组件名
const components = [
  { name: "MaskPack", con: MaskPack },
  { name: "BatchImport", con: BatchImport },
  { name: "BatchImports", con: BatchImports },
  { name: "Tag", con: Tag },
  { name: "InvitationCode", con: InvitationCode },
  { name: "AddInterest", con: AddInterest },
  { name: "AddGuardian", con: AddGuardian },
  { name: "ModelLabel", con: ModelLabel },
  { name: "AddTaltent", con: AddTaltent },
  { name: "AddTaltent2", con: AddTaltent2 },
  { name: "Toexamine", con: Toexamine },
  { name: "FailReason", con: FailReason },
  { name: "Addactorrequirements", con: Addactorrequirements },
  { name: "ViewMengbao", con: ViewMengbao },
  { name: "AddAdvertisement", con: AddAdvertisement },
  { name: "SliderCheck", con: SliderCheck },
  { name: "AddAccount", con: AddAccount },
  { name: "AddProcess", con: AddProcess },
  { name: "AddParameters", con: AddParameters },
  { name: "AddAuth", con: AddAuth },
  { name: "BindingAccount", con: BindingAccount },
  { name: "ProcessingProgress", con: ProcessingProgress },
  { name: "ViewAttachments", con: ViewAttachments },
  { name: "AddProcess2", con: AddProcess2 },
  { name: "Brokerinfo", con: Brokerinfo },
  // { name: "ResetPassword", con: ResetPassword },
]
//循环注册组件
const install = function (Vue, opts = {}) {
  components.forEach(component => {
    Vue.component(component.name, component.con);
  });
};

export default {
  install
};
