let filDate = (e) => {
    if (e == 0 || e == null) {
        return ""
    }
    let d = new Date(e);
    let year = d.getFullYear();
    let month = d.getMonth();
    month = month + 1 > 12 ? 1 : month + 1;
    month = month > 9 ? month : "0" + month.toString();
    let day = d.getDate();
    day = day > 9 ? day : "0" + day.toString();
    let hour = d.getHours();
    hour = hour > 9 ? hour : "0" + hour.toString();
    let minute = d.getMinutes();
    minute = minute > 9 ? minute : "0" + minute.toString();
    // let second = d.getSeconds();
    // second = second > 9 ? second : "0" + second.toString();
    if(hour=="00"&&minute=="00"){
        return `${year}-${month}-${day}`;
    }else{
        return `${year}-${month}-${day} ${hour}:${minute}`;
    }
    
}

export default { filDate }