<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 添加订单 -->
    <div class="mask-main">
      <div class="filei">
        <div class="cen cenable">
          <div class="cen-c cen_flex h56">
            <div class="cen-c2">{{ dataVal.title }}</div>
            <div>
              <button class="btn_1" @click="handleSubmit">确认推送</button>
              <button class="btn_1" @click="handleRefund">取消</button>
            </div>
          </div>
          <div class="search_nav sav">
            <!-- 搜索框 -->
            <el-input v-model="fromObj.keyword" style="width: 13vw" class="search_inp" placeholder="请输入童模ID/姓名/联系电话"
              clearable></el-input>
            <el-select :popper-append-to-body="false" style="width: 9vw" v-model="fromObj.sex" placeholder="请选择性别"
              class="clothing_size">
              <el-option v-for="item in sexList" :key="item.id" :label="item.values" :value="item.values">
              </el-option>
            </el-select>
            <el-select :popper-append-to-body="false" style="width: 9vw" v-model="fromObj.age" placeholder="请选择年龄"
              class="clothing_size">
              <el-option v-for="item in ageList" :key="item.id" :label="item.values" :value="item.values">
              </el-option>
            </el-select>
            <el-cascader style="width: 9vw; margin-right: 0.521vw" :options="data" :props="props" collapse-tags clearable
              v-model="changeTag" @change="handleChangeTag" ref="cascaderHandle" placeholder="请选择才艺特长"></el-cascader>

            <div>
              <el-button @click="handleSeach" class="searchBtn" type="primary">查询</el-button>
              <el-button class="searchBtn clear" type="primary" @click="handleClear">重置</el-button>
            </div>
          </div>
          <div class="cen-table cloth">
            <div class="tablefather">
              <el-table class="center_table" ref="multipleTable" :data="list" tooltip-effect="dark" height="43vh"
                style="width: 100%" border :header-cell-style="{
                                  width: 79.38 + 'vw',
                                  height: 4.416 + 'vh',
                                  background: '#F9F9F9',
                                  fontSize: 0.73 + 'vw',
                                  textAlign: 'center',
                                }" @selection-change="handleSelectionChange">
                <el-table-column type="selection" min-width="48px" align="center">
                </el-table-column>
                <el-table-column align="center" prop="bianhao" label="萌宝ID" min-width="160px">
                  <template slot-scope="scope">
                    <span class="aFu_span" @click="handleDetaul(scope.row.model_id)">{{ scope.row.bianhao }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="name" align="center" label="所在城市" show-overflow-tooltip min-width="130px">
                  <template slot-scope="scope">
                    <span>{{ scope.row.city }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="phone" label="头像" show-overflow-tooltip min-width="130px">
                  <template slot-scope="scope">
                    <div class="headsculpture">
                      <img :src="urls + scope.row.headimage" alt="" srcset="" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="name" label="萌宝姓名" show-overflow-tooltip min-width="89px">
                </el-table-column>
                <el-table-column align="center" prop="sex" label="性别" show-overflow-tooltip
                  min-width="120px"></el-table-column>
                <el-table-column align="center" prop="age" label="年龄" show-overflow-tooltip
                  min-width="120px"></el-table-column>
                <el-table-column align="center" prop="high" label="身高(cm)" show-overflow-tooltip
                  min-width="121px"></el-table-column>
                <el-table-column align="center" prop="weight" label="体重(kg)" show-overflow-tooltip
                  min-width="121px"></el-table-column>
                <el-table-column align="center" prop="shoesize" label="鞋码(码)" show-overflow-tooltip
                  min-width="107px"></el-table-column>
                <el-table-column align="center" prop="createtime" label="出生日期" show-overflow-tooltip min-width="195px">
                  <template slot-scope="scope">
                    <span v-if="scope.row.updatetime != 0">{{
                                          scope.row.birth | DateTime()
                                          }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="phones" label="联系电话" show-overflow-tooltip
                  min-width="195px"></el-table-column>
                <el-table-column align="center" prop="nationality" label="国籍" show-overflow-tooltip
                  min-width="195px"></el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 底部分页 -->
          <div class="footer-page">
            <span>共{{ total_count }}条</span>
            <el-pagination popper-class="paging" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              background :current-page="fromObj.page" :page-sizes="[10, 20, 30, 40]" :page-size="fromObj.count"
              layout="sizes, prev, pager, next, jumper" :total="total_count" :pager-count="5">
            </el-pagination>
          </div>
        </div>
        <!-- 确定弹窗 -->
        <MaskPack :data="pushObj" @childRef="handlePushsCall"></MaskPack>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "ViewMengbao",
  props: ["dataVal"],
  data() {
    return {
      data: [],
      props: {},
      changeTag: [],
      urls: this.imgurl,
      //性别
      sexList: [
        {
          id: 1,
          values: "男",
        },
        {
          id: 2,
          values: "女",
        },
      ],
      //年龄
      ageList: [],
      //才艺特长
      srcList: [],
      pushObj: {
        flag: false, //是否显示
        title: "批量推送预约单", //标题
        text: "确定要批量推送该预约单吗？", //内容
        btns: "确定", //确定按钮
        btnq: "取消", //取消按钮
      },
      fromObj: {
        id: "", //通告id
        status: "4", //状态类型 1=报名的萌宝，2=复选中的萌宝，3=中单的萌宝、4=系统推荐的萌宝
        is_signup: "", //是否报名：1=是 0=否 只有当status = 4时 有这个搜索条件
        page: 1, //当前页码
        count: 10, //每页显示数量
        keyword: "", //童模ID、姓名、联系电话搜索
        sex: "", //性别
        age: "", //年龄
        skill: "", //才艺特长
        is_send: 0,
      },
      list: [], //萌宝列表
      total_count: 0,
      id: "",
      multipleSelection: [],
      inform_info_id:""
    };
  },
  mounted() {
    let query = this.$route.query;
    this.fromObj.id = query.id;
    this.id = query.id;
    this.informSignuplist();
    this.dictionaryGetage();
    this.childGetchildlabel();
  },
  methods: {
    handleChangeTag() {
      if (this.$refs.cascaderHandle.checkedValue.length === 0) {
        this.changeTag = [];
        this.fromObj.skill = "";
      }
    },
    //童模标签 (才艺特长) 列表
    async childGetchildlabel(flag) {
      let res = await this.$api.childGetchildlabel();
      if (res.code == 1) {
        //初始化顶级分类
        let arr = [
          {
            id: 0,
            value: 0,
            label: "所有分类",
            type: 1,
            children: [],
          },
        ];
        res.data.forEach((item, i) => {
          let obj = {
            label: item.name,
            value: item.name,
            name: item.name,
            id: item.id,
            pid: item.pid,
            type: 2,
            i: i,
          };
          //追加一级分类
          arr[0].children.push(obj);
          if (item.sec.length != 0) {
            arr[0].children[i].children = [];
            item.sec.forEach((item2, i2) => {
              if (item2.third.length != 0) {
                item2.third.forEach((item3) => {
                  item3.value = item3.name;
                  item3.label = item3.name;
                });
              }
              let obj2 = {
                label: item2.name,
                name: item2.name,
                id: item2.id,
                value: item2.name,
                pid: item2.pid,
                type: 3,
                //三级分类
                children: item2.third,
                i: i,
                i2: i2,
              };
              //追加二级分类
              arr[0].children[i].children.push(obj2);
            });
          }
        });
        this.data = arr[0].children;
      }
    },
    //搜索
    handleSeach() {
      if (this.changeTag.length != 0) {
        let arr = this.changeTag.flat();
        this.fromObj.skill = arr[arr.length - 1];
      }
      this.fromObj.page = 1;
      this.informSignuplist();
    },
    //得到年龄
    async dictionaryGetage() {
      let res = await this.$api.dictionaryGetage();
      if (res.code == 1) {
        this.ageList = res.data;
      }
    },
    //萌宝报名
    async informSignuplist() {
      let res = await this.$api.informSignuplist({
        ...this.fromObj,
      });
      if (res.code == 1) {
        this.list = res.data.list;
        this.total_count = res.data.total_count;
      }
    },
    //重置
    handleClear() {
      this.fromObj = {
        id: this.id, //通告id
        status: "4", //状态类型 1=报名的萌宝，2=复选中的萌宝，3=中单的萌宝、4=系统推荐的萌宝
        is_signup: "", //是否报名：1=是 0=否 只有当status = 4时 有这个搜索条件
        page: 1, //当前页码
        count: 10, //每页显示数量
        keyword: "", //童模ID、姓名、联系电话搜索
        sex: "", //性别
        age: "", //年龄
        skill: "", //才艺特长
        is_send: 0,
      };
      this.changeTag = [];
      this.informSignuplist();
    },
    //每页显示数量
    handleSizeChange(val) {
      this.fromObj.count = val;
      this.informSignuplist();
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((element) => {
        this.multipleSelection.push(element.model_id);
      });
      this.multipleSelection =
        JSON.parse(JSON.stringify(this.multipleSelection)) + "";
    },
    //查看分页当前页数
    handleCurrentChange(e) {
      this.fromObj.page = e;
      this.informSignuplist();
    },
    //童模详情
    handleDetaul(id) {
      this.$router.push({ path: "/basicInfo", query: { id: id } });
    },
    //取消弹窗
    handleRefund() {
      let data = {
        flag: false,
        submitFlag: false,
      };
      this.$emit("viewObjCall", data);
    },
    handleSubmit() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择推送萌宝",
          type: "error",
        });
        return false;
      }
      this.pushObj.title = "确认推送提示";
      this.pushObj.text = "确认推送所选择的萌宝？";
      this.pushObj.flag = true;
      this.pushObj.flag = true;
    },
    async handlePushsCall(data) {
      if (data.submitFlag) {
        let res = await this.$api.informRemindsign({
          ids: this.multipleSelection.toString(),
          inform_info_id:this.inform_info_id
        });
        if (res.code == 1) {
          this.$message({
            message: "推送成功",
            type: "success"
          })
          // this.informSignuplist()
          this.handleRefund()
        }
      }
      this.pushObj.flag = false;
    }
  },
  watch: {
    dataVal: {
      handler(newVal) {
        console.log(newVal);
        this.inform_info_id = newVal.inform_info_id
        this.informSignuplist();
      },
      deep: true,
    },
  },
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.less");
@import url("@/assets/css/reservation/telement.less");
@import url("@/assets/css/reservation/telement2.less");

.order-mask {
  .mask-main {
    // width: 50.5vw;
    border-radius: 1.104vh 0.52vw 0.7886vh 0.52vw;
    padding: 0;

    .mask-nav {
      border: 0;
    }
  }

  .mask-content {
    display: flex;
    align-items: flex-start;
    font-size: 0.83vw;
    color: #333333;
    height: 6.309vh;
    margin: 2.05vh 0 1.577vh;

    i {
      font-size: 1.25vw;
      color: #066ff8;
      margin-right: 0.521vw;
    }
  }
}

.search_nav {
  flex-wrap: wrap;
  margin-top: 0;
  padding: 0.394vh 1.04vw 1.577vh 1.04vw;

  .search-main {
    display: flex;
  }

  div {
    margin-top: 1.18vh;
  }

  .search_inp {
    width: auto;
  }

  .negative_upload {
    width: auto;
  }

  .searchBtn {
    width: 5vw;
  }
}

.clear {
  color: #999999 !important;
  background-color: #ffffff !important;
  border-radius: 0.312vw !important;
  border: solid 0.05vw #e1e1e1 !important;
}

.btn_1 {
  width: 6.25vw !important;
  height: 3.707vh !important;
  background-color: #ffffff;
  font-size: 0.89vw;
  border-radius: 0.52vw;
  border: solid 0.05vw #6d79ff;
  color: #6d79ff;
}

.detail-main {
  height: 70vw !important;
}

.filei .cen-c {
  width: 70vw;
  height: 4.732vh;
  background-color: #f1f1f1;
  padding: 0;
  margin: 0;
}

.filei .cen-table {
  margin-right: 0;
  margin-left: 1.04vw;
}

.cen-one {
  align-items: flex-end;
}

.filei .cen {
  width: 70vw;
  // height: 27vw;
  background-color: #ffffff;
  border-radius: 1.041vw;
  padding: 0;
}

.tab {
  border-radius: 1.104vh 0.52vw 0 0;
}

.headsculpture {
  height: 2.08vw !important;
  display: flex;
  justify-content: center;

  img {
    width: 2.08vw !important;
    height: 2.08vw !important;
    background-color: #e6e6e6;
    border-radius: 50%;
  }
}

.filei .footer-page .el-pagination {
  margin-right: 1.04vw;
}

.filei .footer-page span {
  margin-left: 1.04vw;
}
</style>