<template>
  <div class="tag">
    <!-- tag标签 -->
    <div class="tag-main" v-if="path != '/'">
      <div class="tag-line"></div>
      <div class="tag-item" v-for="(item, i) in tags" :key="i">
        {{ item.name }}<span v-if="i < tags.length - 1">/</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tags: [],
      path: "",
    };
  },
  mounted() {
    // console.log(this.$route.meta);
    this.tags = this.$route.meta.linkArr;
    this.path = this.$route.path;
  },
  methods: {
    handleTag(item) { },
    handleClose(item) { },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.path = val.path;
        this.tags = val.meta.linkArr;
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
/* // tag标签 */
.tag-main {
  display: flex;
  align-items: flex-end;
  margin: 0.394vh 0 2.445vh;

  .tag-line {
    width: 0.26vw;
    height: 1.814vh;
    background: linear-gradient(0deg, #23d8c2, #6d79ff);
    border-radius: 0.16vw;
    margin-right: 0.521vw;
  }

  .tag-item {
    color: #999999;
    font-size: 0.83vw;

    span {
      margin: 0 0.26vw;
    }
  }

  .tag-item:last-child {
    color: #0c245d;
    font-size: 1.15vw;
    font-weight: bold;
  }
}
</style>