import { render, staticRenderFns } from "./AddAdvertisement.vue?vue&type=template&id=1b1dbe8a&scoped=true&"
import script from "./AddAdvertisement.vue?vue&type=script&lang=js&"
export * from "./AddAdvertisement.vue?vue&type=script&lang=js&"
import style0 from "./AddAdvertisement.vue?vue&type=style&index=0&id=1b1dbe8a&prod&lang=less&scoped=true&"
import style1 from "./AddAdvertisement.vue?vue&type=style&index=1&id=1b1dbe8a&prod&lang=less&"
import style2 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b1dbe8a",
  null
  
)

export default component.exports