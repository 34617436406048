<template>
    <div class="order-mask" v-if="dataValue.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    查看附件
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <el-table class="center_table" ref="multipleTable" :data="authArr" tooltip-effect="dark"
                    style="width: 100%%" height="50vh" border :header-cell-style="{
                        width: 81.822 + 'vw',
                        height: 4.416 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.73 + 'vw',
                        textAlign: 'center',

                    }" @selection-change="handleSelectionChange">
                    <el-table-column align="center" prop="id" type="index" label="序号" min-width="85px">
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="附件名称" min-width="309px">
                        <template slot-scope="scope">
                            <div class="aFu_span" @click="handleImage(scope.row.image)">
                                {{ scope.row.image }}
                                <!-- <img :src="imgurl+scope.row.attachimages" alt=""> -->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="createtime" label="上传时间" min-width="147px">
                    </el-table-column>
                    <el-table-column align="center" prop="adminname" label="上传人" min-width="120px">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "ViewAttachments",
    props: ["dataValue"],
    data() {
        return {
            textarea: "",
            //角色权限列表
            authArr: [

            ],
            multipleTable: []
        };
    },
    mounted() { },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: ""
            }
            this.$emit("attaCell", obj);
        },
        handleSubmit() {
            this.handleRefund();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //点击图片跳转新窗口
        handleImage(img) {
            window.open(this.imgurl + img)
        },
    },
    watch: {
        dataValue: {
            deep: true,
            handler(newvalue, oldvalue) {
                // console.log(newvalue.obj);
                let arr = newvalue.obj.attachimages.split(',');
                this.authArr = []
                arr.forEach((item, i) => {
                    this.authArr.push({
                        image: item,
                        createtime: newvalue.obj.createtime,
                        adminname: newvalue.obj.adminname,
                        id: i + 1
                    })
                })
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 37.92vw;
    box-sizing: border-box;
}

.aFu_span {
    color: #2C72FF;
    cursor: pointer;
}

.aFu_span img {
    max-width: 15.73vw;
    max-height: 7.886vh;
    height: auto;
    width: auto;
    display: block;
}

/deep/.table {
    padding-top: 1.577vh;

    textarea {
        height: 28.391vh;
        resize: none;
    }
}

.order-mask .mask-main .mask-nav {
    padding-bottom: 0;
}
</style>