<template>
    <div class="order-mask" v-if="dataval.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataval.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">参数详情名称</td>
                        <td colspan="3">
                            <input type="text" v-model="users.values" :placeholder="'请输入参数详情名称'">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td">参数详情描述</td>
                        <td colspan="3">
                            <el-input type="textarea" placeholder="请输入参数详情描述" v-model="users.describe" :maxlength="200"
                                show-word-limit>
                            </el-input>
                        </td>
                    </tr>
                </table>
                <div v-if="dataval.btn != ''" class="save" @click="handleSubmit">{{ dataval.btn }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "AddAuth",
    props: ["dataval"],
    data() {
        return {
            radio: 3,
            users: {
                values: "",
                describe: ""
            }
        };
    },
    mounted() {
    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false //判断是否调用新增编辑函数
            }
            this.$emit("parameterCell", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                submitFlag: true, //判断是否调用新增编辑函数
                data: this.users
            }
            this.$emit("parameterCell", obj);
        },
    },
    watch: {
        dataval: {
            deep: true,
            handler(newvalue, oldvalue) {
                if (newvalue.type == "edit") {
                    this.users.values = newvalue.obj.values
                    this.users.describe = newvalue.obj.describe
                } else {
                    this.users.values = "",
                        this.users.describe = ""
                }
            }
        }
    }

};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.el-textarea__inner {
    height: 12.145vh;
    border: none;
    resize: none;
}

.order-mask .mask-main {
    width: 32.97vw;
}

.table table {
    width: 32.97vw;
    margin-top: 1.577vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.83vw;
}
</style>