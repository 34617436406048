import axios from "axios";
import { Message } from 'element-ui';
import { Base64 } from 'js-base64';

let token = window.localStorage.getItem('token')//从本地缓存里取出token
// console.log(token);
// let token = "0fcd9fd9731254eb1d4e2ac15bfe905a";
// let token = "123456";
// 创建通用axios配置
const instance = axios.create({
  baseURL: "https://xcx.modelbaby.com/api/platform/",//根地址
  timeout: 5000,//请求超时时间
  responseType: "json",
  headers: {
    'Content-Type': 'multipart/form-data',
    token: token

  },
});

// 对token加密

// function baseFun() {

//   const base64 = Base64.encode(token + ':')//对token加密
//   return 'Basic ' + base64
// }


//请求拦截

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么localStorage.getItem('token')

    // token = "0fcd9fd9731254eb1d4e2ac15bfe905a"
    // window.localStorage.setItem('token',token)
    if (token) {
      config.headers.token = token
    } else {
      window.location.href = '#login';
    }

    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {

    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    let res = response.data;
    if (res.code == 0) {
      // console.log(Message);

      if (location.hash != "#/login" || location.hash != "#/forgotPassword") {
        if (res.msg != "暂无登录信息！") {
          Message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }


    } else if (res.code == -1) {

      Message({
        showClose: true,
        message: res.msg,
        type: 'error'
      });
      localStorage.clear()
      // location.replace(location.href)
      window.location.href = '#/login';
    }
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if (error.response) {
      let ERRS = error.response.status
      let MSG = error.response.data.msg.msg
      let errdata = ERRS == 401 ? MSG : '服务器发生错误'
      switch (ERRS) {
        case 401:
          // 没有访问权限，token过期，没有携带token请求，token错误
          // Message({
          // 	message:"未授权",
          // 	center:true,
          // })
          Message.alert(errdata, '提示', {
            confirmButtonText: '好的',
            type: "error"
          })
            .then(res => {
              // 跳转到登录界面
              window.location.href = '/login'
            })
            .catch(err => {
              // 跳转到登录界面
              window.location.href = '/login'
            })
          break;
      }
    }
    return Promise.reject(error);
  }
);

//自定义请求

export const post = (url = "", data = {}, headers = {}) => {
  return instance
    .post(url, data, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => err);
};

export const get = (url = "", data = {}, headers = {}) => {
  return instance
    .get(url, {
      params: data,
      headers,
    })
    .then((res) => res.data)
    .catch((err) => err);
};

//自定义指定请求方法
export const request = (method, url, userdata, headers) => {
  let data = {},
    params = {};
  if (method === "get") {
    params = userdata;
  }
  if (["post", "put", "patch"].includes(method)) {
    data = userdata;
  }
  return instance
    .request({
      method,
      url,
      params,
      data,
    })
    .then((res) => res.data)
    .catch((err) => err);
};