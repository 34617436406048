<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div></div>
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <table>
          <tr>
            <td class="table-td xin2">用户名</td>
            <td colspan="3">
              <input type="text" v-model="users.username" placeholder="限4-16个字母和数字，必须以字母开头，不区分大小写" />
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">真实姓名</td>
            <td>
              <input type="text" v-model="users.realname" placeholder="请输入真实姓名" />
            </td>
            <td class="table-td xin2">手机号</td>
            <td>
              <input type="number" v-model="users.phone" placeholder="请输入手机号" />
            </td>
          </tr>
          <tr v-if="users.id">
            <td class="table-td xin2">登录密码</td>
            <td colspan="3">
              <input type="text" v-model="users.password" :placeholder="dataVal.type == 'edit'
                ? '默认为空，输入密码为重置密码'
                : '限6-16个字母和数字'
                " />
            </td>
          </tr>
          <tr v-else>
            <td class="table-td xin2">登录密码</td>
            <td>
              <input type="text" v-model="users.password" :placeholder="dataVal.type == 'edit'
                ? '默认为空，输入密码为重置密码'
                : '限6-16个字母和数字'
                " />
            </td>
            <td class="table-td xin2">确定密码</td>
            <td>
              <input type="text" v-model="reppassword" placeholder="请确认密码" />
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">角色</td>
            <td>
              <el-select :popper-append-to-body="false" v-model="users.role_id" placeholder="请选择角色" class="selec">
                <el-option v-for="item in role_type" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </td>
            <td class="table-td xin2">是否启用</td>
            <td>
              <div style="padding-left: 1.25vw">
                <el-switch v-model="status" active-color="#13ce66" inactive-color="#D7D6D6">
                </el-switch>
              </div>
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">邀请码</td>
            <td>
              <div class="flexDiv">
                <div class="flexDiv-start">{{ users.invitecode }}</div>
                <div class="flexDiv-right">
                  <div class="flexDiv-center"></div>
                  <span class="flexDiv-end" @click="adminSetinvitecode">生成邀请码</span>
                </div>
              </div>
            </td>
            <td class="table-td xin2">确定密码</td>
            <td>
              <el-select :popper-append-to-body="false" v-model="users.area" placeholder="请选择大区" class="selec">
                <el-option v-for="item in arerArr" :key="item.id" :label="item.values" :value="item.values">
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">微信二维码</td>
            <td colspan="3">
              <el-upload class="upload-demo" drag :action="urls + '/api/platform/index/uploadimg'" multiple
                :show-file-list="false" :on-success="handleAvatar" :headers="headers">
                <img class="avater-img" v-if="users.wechat_code" :src="urls + users.wechat_code" alt="" />
                <img v-else src="@/assets/images/icon/erweima.png" class="avatar_i" />
              </el-upload>
            </td>
          </tr>
        </table>
        <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">
          {{ dataVal.btn }}
        </div>
      </div>
      <div class="qrcode" v-if="dataVal.image">
        <img style="width: 10.42vw; height: 15.773vh" :src="imgurl + dataVal.image" alt="" />
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "AddAccount",
  props: ["dataVal"],
  data() {
    return {
      reppassword: "",
      users: {
        username: "", //用户名
        realname: "", //真实姓名
        password: "", //密码
        role_id: "", //所属角色id
        phone: "", //手机号
        status: 1, //禁用 0或启用 1
        area: "", //所属大区
        invitecode: "", //邀请码
        wechat_code: "",
        id: "",
      },
      status: true,
      role_type: [],
      arerArr: [],
      urls: this.imgurl,
      //上传图片文件headers
      headers: {
        token: window.localStorage.getItem("token"),
      },
      qrcode: ""
    };
  },
  mounted() { },
  methods: {
    //头像上传
    handleAvatar(res, file) {
      // console.log(res,file);
      this.users.wechat_code = res.data.imgsrc;
    },
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
        submitFlag: false, //是否调用接口
      };
      this.$emit("addAccountCell", obj);
    },
    async handleSubmit() {
      if (this.users.name == "") {
        this.$message({
          message: "请输入用户名",
          type: "error",
        });
        return false;
      }
      if (this.users.realname == "") {
        this.$message({
          message: "请输入真实姓名",
          type: "error",
        });
        return false;
      }
      if (this.dataVal.type == "add") {
        if (this.users.password == "") {
          this.$message({
            message: "请输入密码",
            type: "error",
          });
          return false;
        }
        if (this.reppassword == "") {
          this.$message({
            message: "请输入确认密码",
            type: "error",
          });
          return false;
        }
        if (this.users.password != this.reppassword) {
          this.$message({
            message: "两次密码输入不一致",
            type: "error",
          });
          return false;
        }
      }
      if (this.users.role_id == "") {
        this.$message({
          message: "请选择所属角色",
          type: "error",
        });
        return false;
      }
      if (this.users.phone == "") {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
        return false;
      }
      if (this.users.area == "") {
        this.$message({
          message: "请选择所属大区",
          type: "error",
        });
        return false;
      }
      if (this.users.invitecode == "") {
        this.$message({
          message: "请先生成邀请码",
          type: "error",
        });
        return false;
      }
      if (this.users.wechat_code == "") {
        this.$message({
          message: "请上传微信二维码",
          type: "error",
        });
        return false;
      }
      this.users.status = this.status ? 1 : 0;
      let res = await this.$api.adminEdit({
        ...this.users,
      });
      if (res.code == 1) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      } else {
        return false;
      }
      let obj = {
        flag: false,
        submitFlag: true, //是否调用接口
        users: this.users,
        qrcode: this.qrcode
      };
      this.$emit("addAccountCell", obj);
    },
    //得到角色
    async adminGetrole() {
      let res = await this.$api.adminGetrole();
      if (res.code == 1) {
        this.role_type = res.data;
      }
    },
    //得到所属大区
    async dictionaryGetarea() {
      let res = await this.$api.dictionaryGetarea();
      if (res.code == 1) {
        this.arerArr = res.data;
      }
    },
    //生成邀请码
    async adminSetinvitecode() {
      let res = await this.$api.adminSetinvitecode();
      if (res.code == 1) {
        this.users.invitecode = res.data.code;
      }
    },
  },
  created() {
    this.adminGetrole();
    this.dictionaryGetarea();
  },
  watch: {
    dataVal: {
      handler(newVal, oldVal) {
        // console.log(newVal, '这样也可以得到数据~~~');
        if (newVal.type == "edit") {
          this.users = { ...newVal.item };
          this.users.password = "";
          this.status = this.users.status == "1" ? true : false;
          this.users.role_id = newVal.item.role_id
        } else if (newVal.type == "add") {
          this.reppassword = "";
          this.users = {
            username: "", //用户名
            realname: "", //真实姓名
            password: "", //密码
            role_id: "", //所属角色id
            phone: "", //手机号
            status: true, //禁用 0或启用 1
            area: "", //所属大区
            invitecode: "", //邀请码
            wechat_code: "",
            id: "",
          };
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/ .upload-demo {
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
    height: 13.407vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    .avater-img {
      width: auto;
      height: 100%;
    }

    .avatar_i {
      width: 3.802vw;
      height: 3.7vw;
    }
  }
}

.flexDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.781vw;
  padding-left: 1.2vw;

  .flexDiv-right {
    display: flex;
    align-items: center;
  }

  .flexDiv-start {
    font-size: 0.73vw;
    color: #333;
  }

  .flexDiv-center {
    height: 1.104vh;
    width: 0.052vw;
    background-color: #aaaaaa;
    margin: 0 1.042vw;
  }

  .flexDiv-end {
    font-size: 0.73vw;
    color: #6d79ff;
    cursor: pointer;
  }
}

.order-mask .mask-main {
  width: 50vw;
  padding: 0;
  border-radius: 1.041vw;
  overflow: hidden;
}

.table table {
  width: 100%;
  margin-top: 1.577vh;
}

.order-mask .mask-main .mask-nav {
  padding: 2.366vh 1.562vw;
  background-color: #f8f8f8;
  margin-bottom: 1.498vh;
}

.order-mask .mask-main .table {
  padding: 0 1.562vw 2.366vh;
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.83vw;
}

.table table .table-td {
  font-weight: bold;
  color: #333;
}

/deep/ .el-input--suffix .el-input__inner {
  padding-left: 1.25vw;
}

.qrcode {
  width: 10.42vw;
  height: 15.773vh;
  margin: 3.549vh auto 0;
}
</style>