<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 添加监护人 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="cen-table">
        <table>
          <tr>
            <td class="xin2">旧密码</td>
            <td class="sel">
              <input
                v-model="users.oldpassword"
                placeholder="请输入旧密码"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="xin2">新密码</td>
            <td>
              <input
                v-model="users.newpassword"
                placeholder="请输入新密码"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="xin2">确认新密码</td>
            <td style="border-bottom: 0.0789vh solid #e6e6e6">
              <input
                type="text"
                v-model="users.qnewpassword"
                placeholder="请确认新密码"
              />
            </td>
          </tr>
        </table>
      </div>
      <div class="mask-footer">
        <button v-if="dataVal.btnq" @click="handleRefund">
          {{ dataVal.btnq }}
        </button>
        <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">
          {{ dataVal.btns }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "resetpassword",
  props: ["dataVal"],
  data() {
    return {
      users: {
        oldpassword: "", //旧密码
        newpassword: "", //新密码
        qnewpassword: "", //确认新密码
      },
    };
  },
  mounted() {},
  methods: {
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
        submitFlag: false,
      };
      this.$emit("resetPasswordCell", obj);
    },
    async handleSubmit() {
      if (this.users.oldpassword == "") {
        this.$message({
          message: "请选择旧密码",
          type: "error",
        });
        return false;
      }
      if (this.users.newpassword == "") {
        this.$message({
          message: "请选择新密码",
          type: "error",
        });
        return false;
      }
      if (this.users.qnewpassword == "") {
        this.$message({
          message: "请输入确认密码",
          type: "error",
        });
        return false;
      }
      if (this.users.newpassword !== this.users.qnewpassword) {
        this.$message({
          message: "新密码和确认新密码不一致",
          type: "error",
        });
        return false;
      }
      //添加或修改监护人
      let res = await this.$api.adminResetpassword({ ...this.users });
      if (res.code == 1) {
        let obj = {
          flag: false,
          submitFlag: true,
        };
        this.$emit("resetPasswordCell", obj);
      }
    },
  },
  watch: {
    dataVal: {
      deep: true,
      handler(newVal, oldVal) {
        this.users = {
          oldpassword: "", //旧密码
          newpassword: "", //新密码
          qnewpassword: "", //确认新密码
        };
      },
    },
  },
};
</script>

<style lang="less">
.cen-table {
  tr {
    .sel {
      .el-input__inner {
        height: 4.259vh;
        border: none;
        width: 19.791vw;
        font-size: 0.83vw;
        color: #000;
        padding-left: 1.041vw;
      }
    }
  }
}
</style>
<style lang="less" scoped>
@import url("@/assets/css/table.css");
@import url("@/assets/css/mask.less");

.mask-main {
  width: auto !important;
}

.cen-table {
  width: auto;
  margin-top: 0.788vh;

  tr {
    width: auto;
    height: 4.416vh;
    text-align: center;
    display: flex;
    background-color: #ffffff;
    border: 0.0789vh solid #e6e6e6;

    td:nth-child(1) {
      width: 7.5vw;
      flex: 1;
      font-family: MicrosoftYaHei-Bold;
      font-size: 0.73vw;
      font-weight: normal;
      letter-spacing: 0.05vw;
      color: #333333;
      background-color: #f9f9f9;
      line-height: 4.416vh;
      border-right: 0.052vw solid #e6e6e6;
      font-weight: bold;
    }

    td:nth-child(2) {
      input {
        width: 19.791vw;
        height: 100%;
        border: 0;
        outline: none;
        box-sizing: border-box;
        padding: 0 1.041vw;
        font-size: 0.83vw;
      }
    }
  }
}

.mask-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.76vh;

  button {
    width: 7.81vw;
    height: 3.627vh;
    border-radius: 0.31vw;
    font-size: 0.73vw;
    cursor: pointer;
    background: #fff;
    border: solid 0.05vw #999999;
    color: #999999;
    margin-right: 3.13vw;
  }

  button:last-child {
    margin-right: 0;
  }

  .btn-bg {
    background: #6d79ff;
    border: 0.0789vh solid #6d79ff;
    color: #fff;
  }
}
</style>