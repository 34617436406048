<template>
  <div class="order-mask" v-if="dataval.flag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ dataval.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <table>
          <tr>
            <td class="table-td xin2">{{ dataval.name }}</td>
            <td colspan="3">
              <input type="text" v-model="users.name" :placeholder="'请输入' + dataval.name">
            </td>
          </tr>
        </table>
        <div v-if="dataval.btn != ''" class="save" @click="handleSubmit">{{ dataval.btn }}</div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "AddAuth",
  props: ["dataval"],
  data() {
    return {
      radio: 3,
      users: {
        name: ""
      }
    };
  },
  mounted() {

  },
  methods: {
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
        typeText: "",
        submitFlag: false
      }
      this.$emit("authCell", obj);
      // this.users = {
      //   name: ""
      // }
    },
    handleSubmit() {
      let obj = {
        flag: false,
        typeText: "",
        submitFlag: true,
        data: this.users
      }
      this.$emit("authCell", obj);
      // this.users = {
      //   name: ""
      // }
    },
  },
  watch: {
    dataval: {
      deep: true,
      handler(newvalue, oldvalue) {
        if (newvalue.type == "edit" || newvalue.type == "2" || newvalue.type == "5") {
          this.users.name = newvalue.obj.name
        } else {
          this.users.name = ""
        }
      }
    }
  }
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
.tab .tab-main .tab-item {
    padding-top: 0;
    border-bottom: 0.1577vh solid #6d79ff;
}
.order-mask .mask-main {
  width: 32.97vw;
}

.table table {
  width: 32.97vw;
  margin-top: 1.577vh;
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.83vw;
}
</style>