<template>
  <div class="order-mask" v-if="data.flag">
    <!-- 添加订单 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <!-- <div></div> -->
          {{ data.title }}
        </div>
        <i class="el-icon-close" @click="handleRefund"></i>
      </div>
      <div class="mask-content">
        <!-- <i class="el-icon-warning-outline"></i> -->
        <div>{{ data.text }}</div>
      </div>
      <div class="mask-footer">
        <button v-show="data.btnq" @click="handleRefund">{{ data.btnq }}</button>
        <button v-show="data.btns" class="btn-bg" @click="handleSubmit">{{ data.btns }}</button>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "MaskPack",
  props: ['data'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    //取消弹窗
    handleRefund() {
      let data = {
        flag: false,
        submitFlag: false
      }
      this.$emit("childRef", data);
    },
    handleSubmit() {
      let data = {
        flag: false,//弹窗是否显示
        submitFlag: true//是否调用接口
      }
      this.$emit("childRef", data);
    },
  },
};
</script>
  
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
  .mask-main {
    width: 24.48vw;
    border-radius: 1.104vh 0.52vw 0.7886vh 0.52vw;

    .mask-nav {
      border: 0;
    }
  }

  .mask-content {
    display: flex;
    align-items: flex-start;
    font-size: 0.83vw;
    color: #333333;
    height: 6.309vh;
    margin: 2.05vh 0 1.577vh;

    i {
      font-size: 1.25vw;
      color: #066ff8;
      margin-right: 0.521vw;
    }
  }

  .mask-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 7.81vw;
      height: 3.627vh;
      border-radius: 0.31vw;
      font-size: 0.73vw;
      background: #fff;
      border: solid 0.05vw #999999;
      color: #999999;
      margin-right: 3.13vw;
      cursor: pointer;
    }

    button:last-child {
      margin-right: 0;
    }

    .btn-bg {
      background: #6D79FF;
      border: 0.0789vh solid #6D79FF;
      color: #fff;
    }
  }
}
</style>