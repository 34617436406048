<template>
  <!-- 添加兴趣爱好 -->
  <div class="order-mask" v-if="dataVal.flag">
    <div class="mask-main">
      <div class="mask-nav">
        <div></div>
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <!-- 搜索框 -->
      <!-- <div class="nav-search">
        
        <el-input
          v-model="keyword"
          style="width: 18vw"
          class="search_inp"
          placeholder="请输入才艺特长"
          clearable
        ></el-input>
        <el-button class="searchBtn" type="primary">查询</el-button>
      </div> -->
      <div class="list">
        <div class="list_left">
          <p class="listleftp">已选</p>
        </div>
        <div class="list_right">
          <div class="list_lists addInt">
            <p class="flex-sh" v-for="item in checklist" :key="item.id">
              {{ item.name }}
              <i @click="handleCheckdelete(item)" class="el-tag__close el-icon-close"></i>
            </p>
          </div>
        </div>
      </div>
      <div class="list list-auto">
        <div class="list_left">
          <p :class="[item.id == oneId ? '' : 'listleftp']" v-for="(item, i) in typelist" :key="item.id"
            @click="handleCheck(i, item.id)">
            {{ item.name }}
          </p>
        </div>
        <div class="list_right">
          <div v-for="item in typechecklist" :key="item.id">
            <div class="list_title">{{ item.name }}</div>
            <div class="list_lists">
              <p :class="[checkId.indexOf(String(v.id)) == -1 ? 'list_p' : '']" v-for="v in item.third" :key="v.id"
                @click="handleCheckright(v, $event)">
                {{ v.name }}
              </p>
            </div>
            <!-- <div v-for="e in typelist[0].child.child"></div> -->
          </div>
        </div>
      </div>
      <div class="mask-footer">
        <button v-if="dataVal.btnq" @click="handleRefund">
          {{ dataVal.btns }}
        </button>
        <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">
          {{ dataVal.btnq }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddInterest",
  props: ["dataVal"],
  data() {
    return {
      keyword: "",
      // 兴趣列表 一级
      typelist: [],
      //二级
      typechecklist: [],
      //已选中的二级
      checklist: [],
      checkId: [],
      oneId: 1,
      oldObj: {},
    };
  },
  mounted() {
    // console.log('ssssssssss',this.data);
  },
  methods: {
    // 选中一级菜单
    handleCheck(i, id) {
      this.typechecklist = this.typelist[i].sec;
      this.oneId = id;
    },
    // 选中二级的标签
    handleCheckright(item, e) {
      if (this.checklist.length < 10) {
        if (this.checkId.indexOf(String(item.id)) == -1) {
          this.checklist.push(item);
          this.checkId.push(String(item.id));
        }
      } else {
        this.$message.error("最多只能选择10项");
      }
    },
    // 删除已选便签
    handleCheckdelete(item) {
      let i = this.checkId.indexOf(String(item.id));
      this.checkId.splice(i, 1);
      this.checklist.splice(i, 1);
    },
    //取消弹窗
    handleRefund() {
      let data = {
        flag: false,
        submitFlag: false,
      };
      this.$emit("AddInterestCell", data);
    },
    handleSubmit() {
      let data = {
        flag: false, //弹窗是否显示
        submitFlag: true, //是否调用接口
        checkId: this.checkId,
        checklist: this.checklist,
      };
      this.$emit("AddInterestCell", data);
    },
  },
  watch: {
    dataVal: {
      deep: true,
      handler(newvalue, oldvalue) {
        // console.log(newvalue);
        this.checkId = [...newvalue.checkId];

        this.checklist = [...newvalue.checklist];

        this.typechecklist = newvalue.childlabel[0].sec;
        this.oneId = newvalue.childlabel[0].id;
        this.typelist = newvalue.childlabel;
        // console.log(this.checkId,this.checklist);
      },
    },
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.css");
@import url("@/assets/css/reservation/telement.less");

.order-mask .mask-main {
  width: 66.667vw;
}

.list-auto {

  .list_left,
  .list_right {
    height: 60vh;
    overflow-y: auto;
  }
}

.nav-search {
  margin: 0.789vh 0 1.577vh 0;
}

.addInt {
  flex-wrap: nowrap !important;
  overflow-x: auto;
  width: 52vw;
  // padding-bottom: 0.789vh;
}

.flex-sh {
  flex-shrink: 0;
}

.search_inp {
  height: 3.627vh;
  background-color: #ffffff;
  border-radius: 0.31vw;
  border: #e1e1e1;
  margin: 0 0.521vw 0 0;
}

.searchBtn {
  width: 6.25vw;
  height: 3.627vh;
  background-color: #6d79ff;
  border-radius: 0vw;
  font-family: MicrosoftYaHei;
  font-size: 0.89vw;
  line-height: 3.627vh;
  padding: 0;
  color: #ffffff;
}

.clear {
  border: 0.0789vh solid #6d79ff !important;
  background-color: white !important;
  color: #6d79ff !important;
}

.mask-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.76vh;

  button {
    width: 7.81vw;
    height: 3.627vh;
    border-radius: 0.31vw;
    font-size: 0.73vw;
    cursor: pointer;
    background: #fff;
    border: solid 0.05vw #999999;
    color: #999999;
    margin-right: 3.13vw;
  }

  button:last-child {
    margin-right: 0;
  }

  .btn-bg {
    background: #6d79ff;
    border: 0.0789vh solid #6d79ff;
    color: #fff;
  }
}
</style>
<!-- 兴趣爱好列表 -->
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.list_left {
  width: 6.25vw;
  background-color: #f8f8f8;

  .listleftp {
    color: black;
  }

  p {
    height: 4.732vh;
    line-height: 4.732vh;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 0.73vw;
    letter-spacing: 0.05vw;
    color: #6d79ff;
    margin: 0;
    cursor: pointer;
  }
}

.list_right {
  flex: 1;
  margin-left: 1.562vw;

  .list_title {
    // width: 100%;
    height: 4.732vh;
    line-height: 4.732vh;
    font-size: 0.73vw;
    font-family: MicrosoftYaHei;
    color: #333333;
  }

  .list_lists {
    max-width: 53.65vw;
    // height: 4.732vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    p {
      width: 5.73vw;
      height: 3.627vh;
      line-height: 3.627vh;
      text-align: center;
      font-family: MicrosoftYaHei;
      font-size: 0.73vw;
      color: #ffffff;
      background-color: #6d79ff;
      border-radius: 0.52vw;
      margin: 0.789vh 0.521vw 0 0;
      display: flex;
      justify-content: space-evenly;
    }

    .list_p {
      background-color: #f8f8f8;
      color: #666666;
      cursor: pointer;
    }

    i {
      line-height: 3.627vh;
      cursor: pointer;
    }
  }
}
</style>