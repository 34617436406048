<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 新增 填写邀请码 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <!-- <div></div> -->
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <el-input type="text" :placeholder="data.name" v-model="textarea" :maxlength="data.maxlength"
                    show-word-limit>
                </el-input>
                <div v-if="codestatus">邀请码错误，请重新输入！</div>
                <div v-else></div>
                <div class="mask-footer">
                    <button v-if="data.btnq" @click="handleRefund">{{ data.btnq }}</button>
                    <button v-if="data.btns" class="btn-bg" @click="childCheckinvitecode">{{ data.btns }}</button>

                </div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "InvitationCode",
    props: ["data"],
    data() {
        return {
            textarea: "",
            radio: 0,
            codestatus: false,
        };
    },
    mounted() {
        console.log(this.data);
    },
    methods: {
        //判断邀请码是否存在 不存在null 存在{}
        async childCheckinvitecode() {
            let res = await this.$api.childCheckinvitecode({
                invitecode: this.textarea
            })
            if (res.code == 1) {
                if (res.data) {
                    this.codestatus = false;
                    this.handleSubmit();
                } else {
                    this.codestatus = true;
                }
            }
        },
        //取消弹窗
        handleRefund() {
            let obj = {
                negativeFlag: false,
                submitFlag: false
            }
            this.$emit("invitationCall", obj);
        },
        handleSubmit() {

            let obj = {
                negativeFlag: false,
                submitFlag: true,
                invitecode: this.textarea
            }

            this.$emit("invitationCall", obj);
        },
    },
    watch: {
        data: {
            deep: true,
            handler(newValue, oldValue) {
                this.textarea = newValue.reason

            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.table {
    padding-top: 1.577vh;

    textarea {
        height: 28.391vh;
        resize: none;
    }

    .el-radio-group {
        text-align: left;
        padding-left: 2.76vw;

        .el-radio__label {
            font-size: 0.83vw;
        }
    }
}

.mask-main {
    width: 29.17vw !important;
    // height: 270px !important;
}

.el-input__inner {
    width: 26.042vw !important;
    height: 4.416vh !important;
}

.save {
    margin: 0 !important;
}

.table>div:nth-child(2) {
    font-family: MicrosoftYaHei;
    font-size: 0.73vw;
    color: #ff0000;
    margin-top: 0.71vh;
}

.table>div:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 4.574vh;
}

.order-mask .mask-main .mask-nav {
    padding-bottom: 2.445vh;
    border-bottom: none;
}

.mask-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4.574vh;

    button {
        width: 7.81vw;
        height: 3.627vh;
        border-radius: 0.31vw;
        font-size: 0.73vw;
        cursor: pointer;
        background: #fff;
        border: solid 0.05vw #999999;
        color: #999999;
        margin-right: 3.13vw;
    }

    button:last-child {
        margin-right: 0;
    }

    .btn-bg {
        background: #6D79FF;
        border: 0.0789vh solid #6D79FF;
        color: #fff;
    }
}

/deep/.el-input__count-inner {
    font-size: 0.625vw;
    padding: 0 0.26vw;
}
</style>