<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 失败原因 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div></div>
        <div class="mask-title">
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <el-input type="textarea" :placeholder="dataVal.name" v-model="textarea" :disabled="disabled"
          :maxlength="dataVal.maxlength" show-word-limit>
        </el-input>
      </div>
      <div class="mask-footer">
        <button v-if="dataVal.btnq" @click="handleRefund">{{ dataVal.btnq }}</button>
        <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">{{ dataVal.btns }}</button>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "FailReason",
  props: ["dataVal"],
  data() {
    return {
      textarea: "",
      disabled: true
    };
  },
  mounted() { },
  methods: {
    //取消弹窗
    handleRefund() {
      let obj = {
        submitFlag: false,
        flag: false,
      }
      this.typeText = "";
      this.$emit("failreasonCell", obj);
    },
    handleSubmit() {
      if (this.textarea == "") {
        this.$message({
          message: "请输入原因",
          type: "error"
        })
        return false;
      }
      let obj = {
        submitFlag: true,
        flag: false,
        typeText: this.textarea
      }
      this.typeText = "";
      this.$emit("failreasonCell", obj);
    },
  },
  watch: {
    dataVal: {
      deep: true,
      handler(newVal, oldVal) {
        console.log(newVal.content);
        this.textarea = newVal.content;
        this.disabled = newVal.disabled;
      }
    }
  }
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.table {
  // padding-top: 1.577vh;

  textarea {
    width: 63.542vw;
    height: 31.25vw;
    resize: none;
  }
}

.order-mask .mask-main .mask-nav {
  border-bottom: 0;
}

.mask-footer {
  padding-top: 1.577vh;
}

/deep/.el-textarea {
  margin-top: 0;
}

/deep/.table .el-textarea.is-disabled .el-textarea__inner {
  background-color: white !important;
}

// .el-textarea.is-disabled .el-textarea__inner</style>