<template>
  <div class="order-mask" v-if="dataval.flag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div>
        <div class="mask-nav">
          <div></div>
          <div class="mask-title">
            {{ dataval.title1 }}
          </div>
          <i v-if="users.is_link"></i>
          <i v-else @click="handleRefund" class="el-icon-close"></i>
        </div>
        <div class="table">
          <table>
            <tr>
              <td class="table-td xin2">广告标题</td>
              <td colspan="3">
                <input type="text" v-model="users.title" placeholder="请输入广告标题" />
              </td>
            </tr>
            <tr>
              <td class="table-td xin2">轮播顺序</td>
              <td colspan="3">
                <input type="number" v-model="users.orderlist" placeholder="请输入广告位置" />
              </td>
            </tr>
            <tr>
              <td class="table-td xin2">广告类型</td>
              <td colspan="3">
                <el-select :popper-append-to-body="false" v-model="users.type" placeholder="请选择广告类型" class="selec">
                  <el-option v-for="item in ad_type" :key="item.values" :label="item.values" :value="item.values">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td class="table-td xin2">是否启用</td>
              <td colspan="3">
                <div ock style="padding-left: 1.25vw">
                  <el-switch v-model="users.status" active-color="#13ce66" inactive-color="#D7D6D6">
                  </el-switch>
                </div>
              </td>
            </tr>

            <tr>
              <td class="table-td xin2">是否添加链接</td>
              <td colspan="3">
                <div ock style="padding-left: 1.25vw">
                  <el-switch v-model="users.is_link" active-color="#13ce66" inactive-color="#D7D6D6">
                  </el-switch>
                </div>
              </td>
            </tr>
          </table>
          <div class="cen-c">
            <div class="cen-c1"></div>
            <div class="cen-c2">广告展示图</div>
          </div>
          <div class="upload">
            <el-upload class="upload-demo" drag :action="urls + '/api/platform/index/uploadimg'" multiple
              :show-file-list="false" :on-success="handleAvatar" :headers="headers" :on-progress="handleVideoProgress2">
              <div v-if="percentage3 != 0" class="flex-pro">
                <el-progress type="circle" :percentage="percentage3"></el-progress>
              </div>
              <img class="avater-img" v-if="users.image && percentage3 == 0" :src="urls + users.image" alt="" />
              <i v-if="!users.image && percentage3 == 0" class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="upload-tip">
              （图片尺寸为*******，单位像素，不超过20M，支持PNG、JPEG、JPG）
            </div>
          </div>
          <div class="mask-footer">
            <button v-if="dataval.btnq" @click="handleRefund">
              {{ dataval.btnq }}
            </button>
            <button v-if="dataval.btns" class="btn-bg" @click="handleSubmit">
              {{ dataval.btns }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="users.is_link">
        <div class="mask-nav">
          <div></div>
          <div class="mask-title">详情页</div>
          <i @click="handleRefund" class="el-icon-close"></i>
        </div>
        <div style="border: 0.0789vh solid #ccc; margin-top: 1.34vh">
          <Toolbar style="border-bottom: 0.0789vh solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
            :mode="mode" />
          <Editor style="height: 39.432vh; overflow-y: hidden" v-model="html" :defaultConfig="editorConfig" :mode="mode"
            @onCreated="onCreated" />
          <!-- <el-input type="textarea" :rows="25" placeholder="请输入内容" v-model="textarea">
                    </el-input> -->
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
let that = "";
export default {
  name: "AddAuth",
  props: ["dataval"],
  data() {
    return {
      radio: 1,
      headers: {
        token: localStorage.token,
      },
      users: {
        title: "", //广告标题
        orderlist: "", //orderlist
        type: "", //广告类型
        status: true,
        is_link: false, //是否存在链接 1是 0否
        link: "", //链接
        image: "", //广告图
        id: "", //id 编辑必填
      },
      ad_type: [],
      headers: {
        token: localStorage.getItem("token"),
      },
      urls: this.imgurl,
      //富文本编辑器
      editor: null,
      html: "",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 所有的菜单配置，都要在 MENU_CONF 属性下
          // 图片上传
          uploadImage: {
            // 后端上传地址，必填
            server: this.imgurl + "/api/platform/index/uploadimg",
            headers: this.headers,
            fieldName: "file",
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 10 * 1024 * 1024, // 10M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["image/*"],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              // token: 'xxx',
              // otherKey: 'yyy'
              // file:''
            },
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              // Accept: 'text/x-json',
              // otherKey: 'xxx'
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,

            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, //10 秒

            // 上传前
            onBeforeUpload(files) {
              return files;
            },
            // 自定义插入图片
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              // 先关闭等待的Message
              let img = that.urls + res.data.imgsrc;
              insertFn(img);
            },

            // 单个文件上传成功之后
            onSuccess(file, res) {
              console.log(`${file.name} 上传成功`, res);
            },

            // 单个文件上传失败
            onFailed(file, res) {
              console.log(`${file.name} 上传失败`, res);
            },

            // 上传进度的回调函数
            onProgress(progress) {
              console.log("progress", progress);
              // progress 是 0-100 的数字
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              console.log(`${file.name} 上传出错`, err, res);
            },
          },
          // 视频上传
          uploadVideo: {
            fieldName: "file",
            server: this.imgurl + "/api/platform/index/uploadfile",
            headers: this.headers,

            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 50 * 1024 * 1024, // 50M

            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 3,
            // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["video/*"],

            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              // token: 'xxx',
              // otherKey: 'yyy'
            },

            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              // Accept: 'text/x-json',
              // otherKey: 'xxx'
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,

            // 超时时间，默认为 30 秒
            timeout: 1000 * 1000, // 1000 秒,
            // 上传之前触发
            onBeforeUpload(file) {
              return file;
            },
            // 自定义插入视频
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              // 先关闭等待的Message
              let img = that.imgurl + res.data.file;
              insertFn(img);
            },
            // 上传进度的回调函数
            onProgress(progress) {
              console.log(progress);
              // onProgress(progress) {       // JS 语法
              // progress 是 0-100 的数字
            },

            // // 单个文件上传成功之后
            // onSuccess(file, res) {
            //   console.log(`${file.name} 上传成功`, res);
            //   this.successMsg(file);
            // },

            // // 单个文件上传失败
            // onFailed(file, res) {
            //   console.log(`${file.name} 上传失败`, res);
            //   this.errorMsg(file);
            // },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              console.log(`${file.name} 上传出错`, err, res);
              Notification.error({
                title: "错误",
                message: `${file.name} 上传失败，请重新尝试`,
              });
            },
          },
        },
      },
      mode: "default", // or 'simple'
      percentage3: 0,
    };
  },
  mounted() {
    that = this;
    this.dictionaryGetadtype();
  },
  methods: {
    handleVideoProgress2(event, file, fileList) {
      // console.log(event, file, fileList);
      this.percentage3 = parseInt(
        ((event.loaded / event.total) * 100).toFixed(0)
      );
    },
    //广告图上传
    handleAvatar(res, file) {
      // console.log(res,file);
      this.users.image = res.data.imgsrc;
      this.percentage3 = 0;
    },
    //广告类型
    async dictionaryGetadtype() {
      let res = await this.$api.dictionaryGetadtype();
      if (res.code == 1) {
        this.ad_type = res.data;
      }
    },
    //富文本编辑器
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
    },
    onChange(editor) {
      console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
    },
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
        typeText: "",
        submitFlag: false,
      };
      this.$emit("addAccountCell", obj);
      this.users = {
        title: "", //广告标题
        orderlist: "", //orderlist
        type: "", //广告类型
        status: true,
        is_link: false, //是否存在链接 1是 0否
        link: "", //链接
        image: "", //广告图
        id: "", //id 编辑必填
      };
    },
    handleSubmit() {

      if (this.users.is_link) {
        this.users.link = this.html.replace(new RegExp(this.urls, "g"), "");
      } else {
      }

      let obj = {
        flag: false,
        typeText: "",
        users: this.users,
        submitFlag: true,
      };
      this.$emit("addAccountCell", obj);
    },
    //图片
    handleAvatarSuccess(response, res, file) {
      if (response.code == 1) {
        this.users.image =
          JSON.parse(JSON.stringify(response.data.imgsrc.replace(/,/g, ""))) +
          "";
      }
    },
    beforeAvatarSuccess(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");
        return false;
      }
      return suffix || suffix2 || suffix3;
    },
  },
  watch: {
    dataval: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.type == "add") {
          this.users = {
            title: "", //广告标题
            orderlist: "", //orderlist
            type: "", //广告类型
            status: true,
            is_link: false, //是否存在链接 1是 0否
            link: "", //链接
            image: "", //广告图
            id: "", //id 编辑必填
          };
          this.html = "";
        } else {
          let data = JSON.parse(JSON.stringify(newVal.item));
          // console.log(data);
          this.users.title = data.title;

          this.users.orderlist = data.orderlist;

          this.users.type = data.type;

          data.status = data.status == 1 ? true : false;
          this.users.status = data.status;

          data.is_link = data.is_link == 1 ? true : false;
          this.users.is_link = data.is_link;
          if (data.link) {
            data.link = data.link.replace(
              new RegExp('href="', "g"),
              'href="' + this.imgurl
            );
            data.link = data.link.replace(
              new RegExp('src="', "g"),
              'src="' + this.imgurl
            );
          }

          this.users.link = data.link;

          this.users.image = data.image;
          this.users.id = data.id;
          this.html = data.link;
        }
      },
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
  components: { Editor, Toolbar },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.w-e-textarea-video-container {
  background-color: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
}

.avater-img {
  width: 100%;
  height: 100%;
}

.order-mask .mask-main {
  display: flex;

  >div {
    width: 42vw;
  }

  >div:nth-child(1) {
    .table {
      padding-right: 2vw;
    }
  }

  >div:nth-child(2) {
    .table {
      padding-left: 2vw;
    }
  }
}

.table table {
  width: 32.97vw;
  margin-top: 1.577vh;

  tr {
    .table-td {
      color: black;
      font-weight: bold;
    }
  }
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.83vw;
}

/deep/ .el-input--suffix .el-input__inner {
  padding-left: 1.25vw;
}

.cen-c {
  display: flex;
  align-items: center;
  margin-top: 3.233vh;
  font-size: 0.89vw;
  font-weight: bold;
}

.cen-c1 {
  width: 0.21vw;
  height: 1.262vh;
  background: #6d79ff;
  margin-right: 0.521vw;
}

.upload {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .upload-tip {
    color: #b2afaf;
    margin-top: 1.656vh;
  }
}

.order-mask .mask-main .mask-nav {
  padding-bottom: 0;
}

.mask-footer {
  margin-top: 1.577vh;

  button {
    width: 6vw;
    height: 2vw;
  }
}

.cen-c {
  margin: 1.577vh 0;
}
</style>
<style lang="less">
.upload .upload-tip {
  font-size: 0.729vw;
}

.el-textarea {
  margin-top: 1.577vh;
}

.avatar-uploader .el-upload {
  border: 0.0789vh dashed #d9d9d9;
  border-radius: 0.31vw;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #6d79ff;
}

.avatar-uploader-icon {
  font-size: 1.46vw;
  color: #8c939d;
  width: 11vw;
  height: 11vw;
  line-height: 11vw;
  text-align: center;
}

.avatar {
  width: 11vw;
  height: 11vw;
  display: block;
}

.radiotyle {
  display: flex;
  padding-left: 1.2vw;
}

span {
  font-size: 1em;
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>