import Vue from 'vue'
import Home from '@/views/Home.vue'
import Router from 'vue-router'

// Vue.use(Router)
//防止路由重复报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//路由
export const constantRouterMap = [
    //首页
    {
        path: '/',
        component: Home,
        children: [
            // 二级路由首页
            {
                path: "/",
                component: () => import('@/views/home/AppMain.vue'),
                children: [
                    // 首页
                    {
                        path: "/",
                        name: "首页",
                        component: () => import('@/views/home/homePage/Index.vue')
                    },
                    //登录日志
                    {
                        path: "/loginLog",
                        name: "登录日志",
                        component: () => import('@/views/home/homePage/LoginLog.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "登录"
                                },
                                {
                                    name: "登录日志"
                                }
                            ]
                        }
                    },
                ]
            },
            //童模库
            {
                path: "/childmodelLibrary",
                component: () => import('@/views/childmodelLibrary/AppMain.vue'),
                redirect: '/reservation',
                children: [
                    //童模列表
                    {
                        path: "/reservation",
                        name: "童模列表",
                        component: () => import('@/views/childmodelLibrary/modelLibraryPages/reservation/Reservation.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模库"
                                }, {
                                    name: "童模列表"
                                }
                            ]
                        }
                    },
                    //新增萌宝
                    {
                        path: "/appointmentInfo",
                        name: "新增童模",
                        component: () => import('@/views/childmodelLibrary/modelLibraryPages/reservation/AppointmentInfo.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模库"
                                }, {
                                    name: "童模列表"
                                }, {
                                    name: "新增童模"
                                }
                            ]
                        }
                    },
                    //萌宝详情
                    {
                        path: "/reservationDetail",
                        component: () => import('@/views/childmodelLibrary/modelLibraryPages/reservation/ReservationDetail.vue'),
                        redirect: '/basicInfo',
                        children: [
                            //基本资料
                            {
                                path: "/basicInfo",
                                name: "基本资料",
                                component: () => import('@/views/childmodelLibrary/modelLibraryPages/reservation/child/BasicInfo.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模库"
                                        }, {
                                            name: "童模列表"
                                        }, {
                                            name: "萌宝详情"
                                        }
                                    ]
                                },
                            },
                            //模卡/自我介绍
                            {
                                path: "/selfIntroduction",
                                name: "模卡/自我介绍",
                                component: () => import('@/views/childmodelLibrary/modelLibraryPages/reservation/child/SelfIntroduction.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模库"
                                        }, {
                                            name: "童模列表"
                                        }, {
                                            name: "萌宝详情"
                                        }
                                    ]
                                },
                            },
                            //获奖经历
                            {
                                path: "/awardedExperience",
                                name: "获奖经历",
                                component: () => import('@/views/childmodelLibrary/modelLibraryPages/reservation/child/AwardedExperience.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模库"
                                        }, {
                                            name: "童模列表"
                                        }, {
                                            name: "萌宝详情"
                                        }
                                    ]
                                },
                            },
                            //才艺/特长
                            {
                                path: "/talentSpeciality",
                                name: "才艺/特长",
                                component: () => import('@/views/childmodelLibrary/modelLibraryPages/reservation/child/TalentSpeciality.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模库"
                                        }, {
                                            name: "童模列表"
                                        }, {
                                            name: "萌宝详情"
                                        }
                                    ]
                                },
                            },
                            //操作记录
                            {
                                path: "/operationRecords",
                                name: "操作记录",
                                component: () => import('@/views/childmodelLibrary/modelLibraryPages/reservation/child/OperationRecords.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模库"
                                        }, {
                                            name: "童模列表"
                                        }, {
                                            name: "萌宝详情"
                                        }
                                    ]
                                },
                            },
                        ]
                    },
                    //童模标签
                    {
                        path: "/childModelLabel",
                        name: "童模标签",
                        component: () => import('@/views/childmodelLibrary/modelLibraryPages/childModelLabel/ChildModelLabel.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模库"
                                },
                                {
                                    name: "童模标签"
                                }
                            ]
                        }
                    },
                    //童模审核
                    {
                        path: "/childModelReview",
                        name: "童模审核",
                        component: () => import('@/views/childmodelLibrary/modelLibraryPages/childModelReview/ChildModelReview.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模库"
                                }, {
                                    name: "童模审核"
                                }
                            ]
                        },
                    },
                    //童模审核详情
                    {
                        path: "/modelreviewDetail",
                        name: "童模审核",
                        component: () => import('@/views/childmodelLibrary/modelLibraryPages/childModelReview/ModelreviewDetail.vue'),
                        redirect: '/basic',
                        children: [
                            //基本资料
                            {
                                path: "/basic",
                                name: "基本资料",
                                component: () => import('@/views/childmodelLibrary/modelLibraryPages/childModelReview/child/BasicInfo.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模库"
                                        }, {
                                            name: "童模审核"
                                        }, {
                                            name: "童模审核详情"
                                        }
                                    ]
                                },
                            },
                            //模卡/自我介绍
                            {
                                path: "/introduction",
                                name: "模卡/自我介绍",
                                component: () => import('@/views/childmodelLibrary/modelLibraryPages/childModelReview/child/SelfIntroduction.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模库"
                                        }, {
                                            name: "童模审核"
                                        }, {
                                            name: "童模审核详情"
                                        }
                                    ]
                                },
                            },
                            //获奖经历
                            {
                                path: "/experience",
                                name: "获奖经历",
                                component: () => import('@/views/childmodelLibrary/modelLibraryPages/childModelReview/child/AwardedExperience.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模库"
                                        }, {
                                            name: "童模审核"
                                        }, {
                                            name: "童模审核详情"
                                        }
                                    ]
                                },
                            },
                            //才艺/特长
                            {
                                path: "/speciality",
                                name: "才艺/特长",
                                component: () => import('@/views/childmodelLibrary/modelLibraryPages/childModelReview/child/TalentSpeciality.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模库"
                                        }, {
                                            name: "童模审核"
                                        }, {
                                            name: "童模审核详情"
                                        }
                                    ]
                                },
                            }
                        ]
                    },
                ]
            },
            //童模运营
            {
                path: "/childmodeloperation",
                component: () => import('@/views/childmodeloperation/AppMain.vue'),
                redirect: '/notificationmanagement',
                children: [
                    //通告管理
                    {
                        path: "/notificationmanagement",
                        name: "通告管理",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/notificationmanagement/Notificationmanagement.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模运营"
                                }, {
                                    name: "通告管理"
                                }
                            ]
                        },
                    },
                    //新增通告
                    {
                        path: "/addNotification",
                        name: "新增通告",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/notificationmanagement/AddNotification.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模运营"
                                }, {
                                    name: "通告管理"
                                }, {
                                    name: "新增通告"
                                }
                            ]
                        },
                    },
                    //通告详情
                    {
                        path: "/notificationDetail",
                        name: "通告详情",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/notificationmanagement/NotificationDetail.vue'),
                        redirect: '/announcementinfo',
                        children: [
                            //通告信息
                            {
                                path: "/announcementinfo",
                                name: "通告信息",
                                component: () => import('@/views/childmodeloperation/modeloperationPages/notificationmanagement/child/Announcementinfo.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模运营"
                                        }, {
                                            name: "通告管理"
                                        }, {
                                            name: "通告详情"
                                        }
                                    ]
                                },
                            },
                            //报名的萌宝
                            {
                                path: "/registeredmengbao",
                                name: "报名的萌宝",
                                component: () => import('@/views/childmodeloperation/modeloperationPages/notificationmanagement/child/RegisteredMengbao.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模运营"
                                        }, {
                                            name: "通告管理"
                                        }, {
                                            name: "通告详情"
                                        }
                                    ]
                                },
                            },
                            //复选中的萌宝
                            {
                                path: "/mengbaoselected",
                                name: "报名的萌宝",
                                component: () => import('@/views/childmodeloperation/modeloperationPages/notificationmanagement/child/Mengbaoselected.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模运营"
                                        }, {
                                            name: "通告管理"
                                        }, {
                                            name: "通告详情"
                                        }
                                    ]
                                },
                            },
                            //中单的萌宝
                            {
                                path: "/midmengbao",
                                name: "中单的萌宝",
                                component: () => import('@/views/childmodeloperation/modeloperationPages/notificationmanagement/child/MidMengbao.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模运营"
                                        }, {
                                            name: "通告管理"
                                        }, {
                                            name: "通告详情"
                                        }
                                    ]
                                },
                            },
                            //系统推荐的萌宝
                            {
                                path: "/systemmengbao",
                                name: "系统推荐的萌宝",
                                component: () => import('@/views/childmodeloperation/modeloperationPages/notificationmanagement/child/SystemMengbao.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模运营"
                                        }, {
                                            name: "通告管理"
                                        }, {
                                            name: "通告详情"
                                        }
                                    ]
                                },
                            },
                            //操作记录
                            {
                                path: "/Operrecords",
                                name: "操作记录",
                                component: () => import('@/views/childmodeloperation/modeloperationPages/notificationmanagement/child/OperRecords.vue'),
                                meta: {
                                    linkArr: [
                                        {
                                            name: "童模运营"
                                        }, {
                                            name: "通告管理"
                                        }, {
                                            name: "通告详情"
                                        }
                                    ]
                                },
                            },
                        ]
                    },
                    //通告审核
                    {
                        path: "/announcementreview",
                        name: "通告审核",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/announcementreview/Announcementreview.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模运营"
                                }, {
                                    name: "通告审核"
                                }
                            ]
                        },
                    },
                    {
                        path: "/announcementreDetail",
                        name: "通告审核",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/announcementreview/AnnouncementreDetail.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模运营"
                                }, {
                                    name: "通告审核详情"
                                }
                            ]
                        },
                    },
                    //广告管理
                    {
                        path: "/advertisement",
                        name: "广告管理",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/advertisement/Advertisement.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模运营"
                                }, {
                                    name: "广告管理"
                                }
                            ]
                        },
                    },
                    //投诉建议
                    {
                        path: "/complaintsuggestions",
                        name: "投诉建议",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/complaintsuggestions/Complaintsuggestions.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模运营"
                                }, {
                                    name: "投诉建议"
                                }
                            ]
                        },
                    },
                    //填写处理进程
                    {
                        path: "/addProcess",
                        name: "填写处理进程",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/complaintsuggestions/AddProcess.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模运营"
                                }, {
                                    name: "填写处理进程"
                                }
                            ]
                        },
                    },
                    //用户管理
                    {
                        path: "/usermanagement",
                        name: "用户管理",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/usermanagement/Usermanagement.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模运营"
                                }, {
                                    name: "用户管理"
                                }
                            ]
                        },
                    },
                    //用户管理详情
                    {
                        path: "/usermanagementDetail",
                        name: "用户管理详情",
                        component: () => import('@/views/childmodeloperation/modeloperationPages/usermanagement/UsermanagementDetail.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "童模运营"
                                }, {
                                    name: "用户管理详情"
                                }
                            ]
                        },
                    }
                ]
            },

            //基础信息
            {
                path: "/basicInformation",
                component: () => import('@/views/basicInformation/AppMain.vue'),
                redirect: '/authorization',
                children: [
                    //功能授权
                    {
                        path: "/authorization",
                        name: "功能授权",
                        component: () => import('@/views/basicInformation/informationPages/authorization/Authorization.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "基础信息"
                                }, {
                                    name: "功能授权"
                                }
                            ]
                        },
                    },
                    //子账号管理
                    {
                        path: "/subAccount",
                        name: "子账号管理",
                        component: () => import('@/views/basicInformation/informationPages/subAccount/SubAccount.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "基础信息"
                                }, {
                                    name: "子账号管理"
                                }
                            ]
                        },
                    },
                    //数据字典
                    {
                        path: "/dataDictionary",
                        name: "数据字典",
                        component: () => import('@/views/basicInformation/informationPages/dataDictionary/DataDictionary.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "基础信息"
                                }, {
                                    name: "数据字典"
                                }
                            ]
                        },
                    },
                    //审批流程
                    {
                        path: "/approvalProcess",
                        name: "审批流程",
                        component: () => import('@/views/basicInformation/informationPages/approvalProcess/ApprovalProcess.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "基础信息"
                                }, {
                                    name: "审批流程"
                                }
                            ]
                        },
                    },
                    //消息设置
                    {
                        path: "/messageSettings",
                        name: "消息设置",
                        component: () => import('@/views/basicInformation/informationPages/messageSettings/MessageSettings.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "基础信息"
                                }, {
                                    name: "消息设置"
                                }
                            ]
                        },
                    },
                    //系统美化
                    {
                        path: "/systemBeautification",
                        name: "系统美化",
                        component: () => import('@/views/basicInformation/informationPages/systemBeautification/SystemBeautification.vue'),
                        meta: {
                            linkArr: [
                                {
                                    name: "基础信息"
                                }, {
                                    name: "系统美化"
                                }
                            ]
                        },
                    },
                ]
            },
        ]
    },
    //登录
    {
        path: "/login",
        name: "login",
        component: () => import('@/views/login/Login.vue')
    }
]

const router = new Router({
    routes: constantRouterMap
})
router.beforeEach(async (to, from, next) => {
    let token = localStorage.getItem('token');
    // let arr = ['/login', '/forgotPassword']
    // if (arr.indexOf(to.name) != -1 && !token) {
    //     next({ name: 'login' })
    // } else {
    //     next()
    // }

    // if (to.name == "login") {
    //     next()
    // } else if (to.name == null) {
    //     next()
    // } else {
    //     if (!token) {
    //         next({ name: 'login' })
    //     } else {
    next()
    //     }
    // }
})
export default router